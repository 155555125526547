import styled from "styled-components";

export const StyledCompanyGraph = styled.div`
    margin: 1em;

    @media print {
        overflow: visible !important;
        position: static !important;
        width: fit-content;
    }
`