import React, { useState, useEffect } from "react";
import sortableTables from "./sortable-tables";
import "./sortable-tables.css";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";

export default function DashboardTable() {
  const dateParse = (date) => {
    let parseDate = new Date(date);
    parseDate = new Date(parseDate.getTime() + parseDate.getTimezoneOffset() * 60000)

    const month = parseDate.toLocaleString('default', { month: 'short' });
    const day = parseDate.getDate();
    const year = parseDate.getFullYear();
    return (month + " " + day + ", " + year);
  };

  let [filteredCompanies, setFilteredCompanies] = useState([]);
  const [dashboardData, setDashboardData] = useState();


  const { execute } = useFetchWithMsal({
    scopes: protectedResources.getDashboard.scopes.read,
  });

  useEffect(() => {
    if(!dashboardData){
       execute("GET", protectedResources.getDashboard.endpoint, protectedResources.getDashboard.scopes).then((response) => {
        setDashboardData(response);
    })
  }
  },[execute]);


  useEffect(() => {
    if(dashboardData){
      const data = dashboardData;
      let companies = [];

      for (let i = 0; i < data.length; i++) {
        companies.push({
          id: data[i].testid,
          accountName: data[i].companyname,
          score: data[i].testscore,
          date: dateParse(data[i].date.slice(0, 10)),
        });
      }
  
      setFilteredCompanies(companies);
    }
  }, [dashboardData]);

  useEffect(() => {
    //initializing enable-a11y sortable tables module
    sortableTables.init();
  }, []);

  return (
    <div className="table-section">
      <figcaption id="fig-caption">
        <div className="centered-text">
          <p id="table-title">Accessibility Indicator Data (AID) Scores by Account</p>
        </div>
      </figcaption>
      <div className="table-container">
        <div
          className="deque-table-sortable-group"
          id="overview-sortable-table"
        >
          <div id="account-overview-table__sort-instructions">
            <div className="centered-text">
              Click the table heading buttons to sort the table by the data in
              its column.
            </div>
          </div>
          <table
            id="account-overview-table"
            className="deque-table-sortable"
            role="grid"
            data-aria-live-update="The table ${caption} is now ${sortedBy}"
            data-ascending-label="Sorted in ascending order"
            data-descending-label="Sorted in descending order"
            aria-labelledby="fig-caption"
          >
            <thead>
              <tr>
                <th scope="col" id="account-col">
                  <button
                    className="sortableColumnLabel"
                    aria-describedby="account-overview-table__sort-instructions"
                  >
                    Account Name
                  </button>
                </th>
                <th scope="col">
                  <button
                    className="sortableColumnLabel"
                    aria-describedby="account-overview-table__sort-instructions"
                  >
                    Most Recent Accessibility Score
                  </button>
                </th>
                <th scope="col">
                  <button
                    className="sortableColumnLabel"
                    aria-describedby="account-overview-table__sort-instructions"
                  >
                    Date of Most Recent Test
                  </button>
                </th>
              </tr>
            </thead>
            <tbody id="account-overview-body">
              { filteredCompanies.length > 0 && filteredCompanies.map((company, idx) => (
                <tr key={idx}>
                  <th className="accNameLabel" scope="row">
                    <a 
                      href={`/company/${company.accountName}`} 
                      className="table-link"
                      aria-label={`${company.accountName} test summary page`}  
                    >
                      {company.accountName}
                    </a>
                  </th>
                  <td role="gridcell">{Math.round(company.score)}</td>
                  <td role="gridcell">{company.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="centered-text">
            <span
              className="deque-table-sortable__live-region sr-only"
              aria-live="assertive"
              data-read-captions="false"
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
}
