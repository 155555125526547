import { useEffect, useRef } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ScoringModal = ({ showModal, setShowModal, onConfirmCallback, onCancelCallback }) => {
    const dialogRef = useRef();

    useEffect(() => {
        if (showModal) {
            dialogRef.current.showModal();
        } else {
            dialogRef.current.close();
        }
    }, [showModal]);

    return (
        <dialog
            ref={dialogRef}
            id="abandonDialog"
            aria-modal="true"
            aria-labelledby="abandonDialog__label"
            aria-describedby="abandonDialog__description"
        >
            <span className="sr-only">Now displaying the abandon question modal.</span>
            <button
                className="x"
                title="Close this box"
                onClick={() => setShowModal(false)}
            >
                <CloseRoundedIcon />
            </button>
            <div role="document">
                <h2 className="modal_title" id="abandonDialog__label">
                    You have unsaved changes that will be lost if you leave this page.
                </h2>
                <p className="modal_description" id="abandonDialog__description">
                    Do you wish to proceed?
                </p>
                <div className="modal_options">
                    <div className="modal_btn_container">
                        <button 
                            type="reset" 
                            className="modal_button modal_cancel" 
                            onClick={() => onCancelCallback()}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="modal_btn_container">
                        <button 
                            type="submit" 
                            className="modal_button modal_login"
                            onClick={() => onConfirmCallback()}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </dialog>
    )
};

export default ScoringModal;