import styled from "styled-components"

export const FieldContainer = styled.div`
  padding: 30px;
  background-color: #ffffff;
  margin: 30px 0;
  border-radius: 28px;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07);

  @media print {
    box-shadow: none;
    border: 2px black solid;
  }

  .fields {
    font-size: 1.17em;
    margin: 0 0 20px 0;
    display: block;

    :after {
      content: "*";
      color: #dc0000;
    }
  }

  select {
    width: 100%;
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
`

export const HeaderContainer = styled.div`
  margin-top: 1rem;  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  button, .btn {
    margin: 5px;
    text-transform: none;
    padding: 1em;

  }

  .summary {
    margin-right: auto;

    .icon {
      font-size: 1rem;
    }
  }

  .save {
    color: #2f7abc;
    border-color: #2f7abc;
    background-color: white;

    :hover {
      border-width: 2px;
      border-style: solid;
      border-color: #1d4c72;
      background-color: #1d4c72;
      color: white;
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;
  }

  .MuiButton-startIcon {
    margin: 0;
  }

  .Mui-disabled {
    color: white;
    opacity: 60%;
  }

  @media (max-width: 1000px) {
    margin-top: 0rem;

    button, .btn {
      font-size: 0.75rem;
      padding: 1em 0.8em;
    }
  }
`

export const CategoryContainer = styled.div`
  padding: 10px 0;

  h1 {
    margin: 20px 0;
  }

  span.highlight {
    color: #FF5C6C;
    font-size: 0.8rem;
  }

  span.required {
    color: red;
  }

  a{
    color: #FF5C6C;
  }
`

export const ImageFieldContainer = styled.div`
  text-align: center;

  img {
    width: 100%;
    margin-bottom: 1rem;
  }

  .icon {
    font-size: 50px;
    color: black;
  }

  button {
    text-transform: none;

    border-width: 2px;
    border-style: solid;
    color: #2f7abc;
    border-color: #2f7abc;
    background-color: white;

    :hover {
      border-width: 2px;
      border-style: solid;
      border-color: #1d4c72;
      background-color: #1d4c72;
      color: white;
      text-decoration: underline;
    }
  }

  sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; /* 3 */
  }

  .file-upload {
    &__container {
      position: relative;
      display: block;
      width: 50px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }

    &__input {
      opacity: 0;
    }

    &__input {
      &:focus + &__facade {
        outline: solid 1px blue;
      }
    }

    &__facade {
      background: white;
      pointer-events: none;
    }

    &__input,
    &__facade {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      cursor: pointer;                                                                                                                                             
    }
  }

  @media only screen and (max-width: 1000px) {
    img {
      width: 100%;
    }
  }
`
