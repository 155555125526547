import styled from "styled-components";

export const LegendStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5em 2em;
  margin-left: 2rem;
  margin-right: 2rem;
  border: 1px black solid;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: black;
  border-radius: 28px;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const LegendText = styled.div`
  text-align: center;
  color: white;

  @media print {
    color: white;
  }
`;

export const ColourDiv = styled.div`
  width: 13px;
  height: 13px;
  background-color: white;
  margin: 0;
  margin-right: 5px;
  padding: 0;
  display: inline-block;
`;

export const LegendParaFormat = styled.div`
  margin-top: 0em;
  margin-bottom: 0em;
`;

export const BoldedText = styled.b`
  font-weight: 900;

  @media (max-width: 1100px) {
    ::before {
      content: "";
      white-space: pre;
      display: block;
    }
  }
`;
