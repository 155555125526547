import { useState, useCallback } from "react";
import { InteractionType, PopupRequest } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = (msalRequest) => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      ...msalRequest,
      account: instance.getActiveAccount(),
      redirectUri: "/home",
    }
  );

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, if any
   * @returns JSON response
   */
  const execute = async (method, endpoint, scope, data = null) => {
    if (msalError) {
      setError(msalError);
      return;
    }

    if (result) {
      try {
        let response = null;

        const headers = new Headers();
        const token = (await instance.acquireTokenSilent(scope)).accessToken;
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);

        if (data) headers.append("Content-Type", "application/json");

        let options = {
          method: method,
          headers: headers,
          body: data ? JSON.stringify(data) : null,
        };

        setIsLoading(true);

        response = await fetch(endpoint, options);

        // convering to json is causing issues on delete endpoints
        if (method === "DELETE" && response.status === 200) {
          setData("");
          setIsLoading(false);
          return "";
        }

        let resData;
        const contentType = response.headers.get("content-type");
        if (contentType?.includes("application/json")) {
          resData = await response.json(); 
        } else {
          resData = await response.text();
        }
        setData(resData);
        setIsLoading(false);
        return resData;
      } catch (e) {
        setError(e);
        setIsLoading(false);
        throw e;
      }
    }
  };

  return {
    isLoading,
    error,
    data,
    execute: useCallback(execute, [result, msalError]),
  };
};

export default useFetchWithMsal;
