import React from "react"
import {
  TableElement,
  TableData,
  StyledTable,
  StyledFigure
} from "./__styled__/Answers"

export const PureAnswerTable = ({
  answers,
  answerFields,
  onLabelInputChange,
  onGuidanceInputChange,
  shouldShowErrors
}) => {
  return (
    <StyledFigure>
      <figcaption id="fig-caption">
        The following table depicts the scoring which the tester will use to
        assess the website's compliance with the criteria being tested.
      </figcaption>
      <div className="table-container" style={{ margin: "20px auto" }}>
        <StyledTable aria-labelledby="fig-caption">
          <thead>
            <tr>
              {answerFields.map((field) => (
                <TableElement key={field} scope="col">
                  {field}
                </TableElement>
              ))}
            </tr>
          </thead>
          <tbody>
            {answers && answers.length > 0 && answers.map((ans, idx) => (
              <tr key={ans.score}>
                <TableElement scope="row">
                  {ans.score !== null && ans.score !== -1 ? ans.score : "N/A"}
                </TableElement>
                <TableData>
                  <textarea
                    className="textarea"
                    placeholder="Label Text"
                    defaultValue={ans.reasoning}
                    required={true}
                    aria-label={`Label for ${ans.score !== null ? `${ans.score} points`: 'not applicable'} answer`}
                    onChange={(event) =>
                      onLabelInputChange(event.target.value, idx)
                    }
                    rows={8}
                  />
                  {shouldShowErrors && (ans.reasoning === "" || ans.reasoning === undefined) &&
                    <div id="selectError" className="error_label" style={{ paddingTop: 7 }}>
                      Please enter a value
                    </div>
                  }
                </TableData>
                <TableData>
                  {ans.feedback !== null && ans.score !== -1 && (
                    <>
                    <textarea
                      className="textarea"
                      placeholder="Guidance Text"
                      defaultValue={ans.feedback}
                      required={true}
                      aria-label={`Guidance for ${ans.score !== null ? `${ans.score} points`: 'not applicable'} answer`}
                      onChange={(event) =>
                        onGuidanceInputChange(event.target.value, idx)
                      }
                      rows={8}
                    />
                    { shouldShowErrors && (ans.feedback === "" || ans.feedback === undefined) &&
                      <div id="selectError" className="error_label" style={{ paddingTop: 7 }}>
                        Please enter a value
                      </div>
                    }
                    </>
                  )}
                </TableData>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </StyledFigure>
  )
};