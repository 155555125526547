import { TestMap } from "./aidTestMap";
import { getItem } from "../utilities/storage";

/*
    getResult
    Loops through the aidTestMap and the WaveResults (stored in local)
    and returns the results 

    resultType: One of errors, alerts, contrast, aria, features, structural
    testNum: The pageId(number) of the tests to run
*/
function getResult(testId, type, tests) {
    let waveResults = getItem(testId).wave_results;
    let results = [];

    if(tests && waveResults[type]) {
        tests.map((test) => {
            for(let i = 0; i < waveResults[type].length; i++) {
                let obj = waveResults[type][i];
                if(obj.slug === test) {
                    results.push(obj);
                }
            }
        })
    }

    return results;
}

export function getIssues(curTestId, pageId, execute) {
    if (typeof TestMap.get(pageId)?.tests === 'function') {
        return TestMap.get(pageId)?.tests(curTestId, execute);
    } else {
        return new Promise((resolve, reject) => {
            resolve([].concat(
                getResult(curTestId, 'errors', TestMap.get(pageId)?.tests.errors), 
                getResult(curTestId, 'alerts', TestMap.get(pageId)?.tests.alerts),
                getResult(curTestId, 'contrast', TestMap.get(pageId)?.tests.contrast)
            ));
        })
    }
}