import styled from "styled-components";

export const StyledDropdownContainer = styled.div`
  display: flex;
  margin-top: 1em;
  padding-left: 0.9em;
  padding-right: 0.9em;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.9em;

  @media print {
    display: none;
  }

  @media (max-width: 675px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledDropdown = styled.div`
  width: 10em;
`;

export const StyledSelect = styled.select`
  background-color: black;
`;

export const Styledlabel = styled.label`
  font-size: 1rem;
`;
