import { Icon } from "@iconify/react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState, useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import "./__styled__/questions.css";
import { useNavigate } from "react-router-dom";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";

let dialogIndex = 0;

export const EditButton = ({ criteria, index, descby }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/questionnaire", {
      state: { criteria },
    });
  };

  return (
    <div>
      <button
        onClick={onClick}
        role="button"
        className="edit-btn"
        aria-label="Edit this criteria"
        aria-describedby={descby}
      >
        <EditIcon inline={true} className="mui-edit-icon" />
      </button>
    </div>
  );
};

export const DeleteButton = ({ id, testSetCriteria, descby, execute }) => {
  const dialogRef = useRef(null);
  dialogIndex++;

  const delete_question = async () => {
    try {
      execute("DELETE", protectedResources.deleteCriteria.endpoint + id, protectedResources.deleteCriteria.scopes).then(() => {
        testSetCriteria();
      });

      dialogRef.current.close();
    } catch (err) {
      console.error("DeleteButton", JSON.stringify(err));
    }
  };

  return (
    <>
      <dialog
        ref={dialogRef}
        id="delDialog"
        aria-modal="true"
        aria-labelledby="Displaying the delete question modal"
        aria-describedby={`delete_label--${dialogIndex}`}
      >
        <span className="sr-only">Now displaying delete modal.</span>
        <button
          className="x"
          title="close this box"
          onClick={() => dialogRef.current.close()}
        >
          <CloseRoundedIcon />
        </button>
        <div role="document">
          <h2 className="modal_title" id={`delete_label--${dialogIndex}`}>
            Are you sure you would like to delete this question?
          </h2>
          <p className="modal_description" id={`delete_desc--${dialogIndex}`}>
            This action cannot be undone
          </p>
          <div className="modal_options">
            <div className="modal_btn_container">
              <button
                type="reset"
                className="modal_button modal_cancel"
                onClick={() => dialogRef.current.close()}
              >
                Cancel
              </button>
            </div>
            <div className="modal_btn_container">
              <button
                type="submit"
                className="modal_button modal_delete"
                onClick={() => delete_question()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </dialog>

      <button
        onClick={() => dialogRef.current.showModal()}
        id="dele"
        className="delete-btn"
        title="Delete this question"
        aria-describedby={descby}
      >
        <Icon icon="gg:trash-empty" inline={true} width={15} height={15} />
      </button>
    </>
  );
};

const DataTable = () => {
  const navigate = useNavigate();
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.getAllCriteria.scopes.read,
  });

  let [criterias, setCriterias] = useState();

  useEffect(() => {
    execute("GET", protectedResources.getAllCriteria.endpoint, protectedResources.getAllCriteria.scopes).then(
      (response) => {
        setCriterias(response);
      }
    );
  }, [execute]);

  const add_question = async () => {
    navigate("/questionnaire", {
      state: { isNewQuestion: true },
    });
  };

  const testSetCriteria = () => {
    execute("GET", protectedResources.getAllCriteria.endpoint, protectedResources.getAllCriteria.scopes).then(
      (response) => {
        setCriterias(response);
      }
    );
  };

  return (
    <>
      <div className="container-header">
        <div className="table-title">
          <h1 className="page-header" style={{ margin: 0 }}>
            Testing Criteria
          </h1>
        </div>
        <button onClick={add_question}>Add Question</button>
      </div>

      <table
        className="table-container"
        role="grid"
        aria-label="Testing Criteria"
        aria-colcount="4"
      >
        <thead className="table-header">
          <tr className="inner-headers" role="row" aria-rowindex="1">
            <th
              className="header-content question-col"
              scope="col"
              data-field="id"
              tabIndex="-1"
              aria-colindex="1"
            >
              Question
            </th>
            <th
              className="header-content criteria-col"
              scope="col"
              data-field="criteria"
              tabIndex="-1"
              aria-colindex="2"
            >
              Criteria
            </th>
            <th
              className="header-content edit-col"
              scope="col"
              data-field="edit"
              tabIndex="-1"
              aria-colindex="3"
            >
              Edit
            </th>
            <th
              className="header-content delete-col"
              scope="col"
              data-field="delete"
              tabIndex="-1"
              aria-colindex="4"
            >
              Delete
            </th>
            <th
              className="header-content actions-col"
              scope="col"
              data-field="actions"
              tabIndex="-1"
              aria-colindex="3"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {criterias &&
            criterias.map((item, index) => {
              return (
                <tr key={index} className="row-content" role="row">
                  <td
                    className="row-data"
                    id="question-data"
                    data-field="id"
                    tabIndex="-1"
                  >
                    {index + 1}
                  </td>
                  <td
                    className="row-data"
                    id={"explanation" + index + 1}
                    data-field="criteria"
                    tabIndex="-1"
                  >
                    {item.criteria}
                  </td>
                  <td
                    className="row-data"
                    id="edit-data"
                    data-field="edit"
                    tabIndex="-1"
                  >
                    <EditButton
                      criteria={item}
                      index={index + 1}
                      descby={"explanation" + index + 1}
                    />
                  </td>
                  <td
                    className="row-data"
                    id="delete-data"
                    data-field="delete"
                    tabIndex="-1"
                  >
                    <DeleteButton
                      id={item.criteriaid}
                      testSetCriteria={testSetCriteria}
                      descby={"explanation" + index + 1}
                      execute={execute}
                    />
                  </td>
                  <td
                    className="row-data"
                    id="actions-data"
                    data-field="actions"
                    tabIndex="-1"
                  >
                    <EditButton criteria={item} index={index + 1} />
                    <DeleteButton
                      id={item.criteriaid}
                      testSetCriteria={testSetCriteria}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default DataTable;
