import React, { useState, useEffect } from "react";
import { ImageFieldContainer } from "./__styled__/EditQuestionnaire";

export const ImageDropbox = ({
  image,
  imageFieldName,
  onImageChange,
  isUpload,
  id,
}) => {
  let [imageVisual, setImageVisual] = useState(image);

  useEffect(() => {
    if (image) {
      setImageVisual(image);
    }
  }, [image]);

  const onUploadClick = (imageFieldName, file) => {
    isUpload = false;
    getBase64(file);
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let img = reader.result;
      if (img.indexOf(",")) {
        img = img.substring(img.indexOf(",") + 1);
      }
      setImageVisual(img);
      onImageChange(imageFieldName, img);
    };
    reader.onerror = function (error) {
      console.error(error);
    };
  }

  const onRemoveClick = () => {
    setImageVisual(null);
    onImageChange(imageFieldName, null);
  };

  return (
    <ImageFieldContainer>
      {imageVisual ? (
        <div>
          <img alt={imageFieldName} src={ isUpload ? (URL.createObjectURL(imageVisual)) : ("data:image/png;base64," + imageVisual.substr(imageVisual.indexOf(',') + 1)) }/>
          <br />
          <button id={id} onClick={onRemoveClick}>
            Remove Screenshot
          </button>
        </div>
      ) : (
        <>
          <div className="file-upload__container">
            <label className="sr-only" htmlFor="my-file-input">
              Upload screenshot of a visual example
            </label>

            <input
              type="file"
              id="my-file-input"
              className="file-upload__input"
              name="file1"
              accept="image/*"
              onClick={(event) =>
                onUploadClick(imageFieldName, event.target.files[0])
              }
              onChange={(event) => {
                onUploadClick(imageFieldName, event.target.files[0]);
              }}
            />

            <svg
              className="file-upload__facade"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="NoteAddIcon"
            >
              <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z"></path>
            </svg>
          </div>

          <p>Add file: JPEG, MP4, PNG, PDF</p>
        </>
      )}
    </ImageFieldContainer>
  );
};
