import React, { useState, useEffect } from "react";
import "./scan-modal.css";
import "../Scoring_Screen/scoringscreen.css";
import { useNavigate } from "react-router-dom";
import { cancelScan, getFullResults, getScanStatus, startScan } from "../../utilities/api";
import { getItem, updateItem } from "../../utilities/storage";
import { protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";

export const ScanModal = (props) => {
  const navigate = useNavigate();
  const { execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.addPopeTech.scopes.write,
    ],
  });
  const [status, setStatus] = useState('scan started');
  const testId = new URLSearchParams(window.location.search).get("testId");
  const { modalRef, modalControl, lighthouse } = props;
  let websiteId = '';

  // start the scan
  useEffect(() => {
    websiteId = getItem(testId).website_id;
    startScan(websiteId).then((resp) => {
        updateItem(testId, { scan_id: resp.data.scans[0].public_id});
    });

    // poll scan server for updates
    const intervalId = setInterval(() => {
      getScanStatus(websiteId).then((resp) => {
        let statusMsg = resp.data[0].status;
        let time = resp.data[0].queued_at_formatted;
        setStatus(statusMsg + ' ' + time);
        if(statusMsg === 'success') {
            clearInterval(intervalId);
            
            setStatus('Retrieving Scan Results');
            getFullResults(websiteId).then((resp) => {
                // get the scan results before moving
                if(resp.length === 0) {
                    updateItem(testId, { scan: false });
                    alert("Pope Tech returned an empty result. This isn't an error from Pope Tech, and the audit will continue without the scan results. Most likely the bot has been blocked. If the bot is unblocked, or another root cause resolved, you can run the scan again.");
                }
                setStatus('Saving Results');
                updateItem(testId, { wave_results: resp });
            })
        }
      });
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if(status === 'Saving Results' && (lighthouse === 'Completed' || lighthouse === 'Error')) {
      if(lighthouse === 'Error') {
        alert('The lighthouse scan had failed. The test results will not be available for lighthouse.');
      }
      const popetech = {
          scanid: getItem(testId).scan_id,
          waveresults: getItem(testId).wave_results,
      };
      execute("PUT", protectedResources.updatePopeTechInfo.endpoint + testId, protectedResources.updatePopeTechInfo.scopes, popetech);
      setTimeout(() => {
          navigate(`/scoringScreen?testId=${testId}`);
      }, 3000);
    }

    
  },[status, lighthouse]);

  const handleButtonClick = () => {
    // cancel scan
    cancelScan(getItem(testId).scan_id).then(resp => {
      modalControl(false);
    });
  }

  return (
    <>
     <dialog className="scan-modal" ref={modalRef}>
     <form method="dialog">
        <button className="x" onClick={handleButtonClick} aria-label="close"><svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseRoundedIcon"><path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg></button>
          <h1 className="scan-title">Scan Pages</h1>
          <div>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            <p className="status-msg" aria-live="polite" aria-atomic="true">Pope Tech: <span>{status}</span></p>
            <p className="status-msg" aria-live="polite" aria-atomic="true">Lighthouse: <span>{lighthouse}</span></p>
          </div>
          <button onClick={handleButtonClick}>Cancel Scan</button>
      </form>
      </dialog>
    </>
  );
};
