import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import dialogPolyfill from 'dialog-polyfill';

const ModalComp = () => {
  const navigate = useNavigate();
  const dialogRef = useRef();

  const validatePassword = () => {
    const tempPass = "admin123";
    const password = document.getElementById(
      "admin-modal-password-input"
    ).value;

    if (password == tempPass) {
      navigate("/questions");
      dialogRef.current.close();
    } else {
      window.alert("Invalid password!");
    }
  };

  useEffect(() => {
    // If we are using the polyfill, then initialize it
    if (window.dialogPolyfill) {
      dialogPolyfill.registerDialog(dialogRef.current);
    }
  })

  return (
    <dialog
      ref={dialogRef}
      id="favDialog"
      aria-modal="true"
      aria-labelledby="favDialog__label"
      aria-describedby="favDialog__description"
    >
      <div role="document">
        <span className="sr-only">Now displaying admin login modal.</span>
        <button className="x" title="close this box" onClick={() => dialogRef.current.close()}>
          <CloseRoundedIcon/>
        </button>
        <h2 className="modal_title" id="favDialog__label" title="Admin Login">Admin Login</h2>
        <label 
          id="favDialog__description" 
          htmlFor="admin-modal-password-input"
          className="modal_description"
        >
          Please use the admin password to login.
        </label>
        <input
          type="password"
          id="admin-modal-password-input"
          placeholder="Password"
          defaultValue=""
          required
        />
        <div className="modal_options">
          <div className="modal_btn_container">
            <button 
              type="reset" 
              className="modal_button modal_cancel" 
              onClick={() => dialogRef.current.close()}
            >
              Cancel
            </button>
          </div>
          <div className="modal_btn_container">
            <button 
              type="submit" 
              className="modal_button modal_login" 
              onClick={() => validatePassword()}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
};
export default ModalComp;
