import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility.js";
import { StyledCompanyGraph } from "./__styled__/CompanyGraph";
import NumTests from "../NumTests";
import Legend from "../Legend";
import { useEffect, useState } from "react";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";


highchartsAccessibility(Highcharts);

//[INSERT_COMPANY_HERE] to be replaced with company name when graph is rendered
const options = {
  title: {
    align: "center",
    text: "[INSERT_COMPANY_HERE] Accessibility Scores",
  },
  accessibility: {
    beforeChartFormat: {},
    announceNewData: {
      enabled: true,
    },
    point: {
      valueDescriptionFormat: "Date of test is {point.x}, score is {point.y}%.",
    },
    keyboardNavigation: {
      enabled: true,
    },
  },
  caption: {
    text: "<b>This chart indicates all of the accessibility test scores of [INSERT_COMPANY_HERE].</br>",
  },
  xAxis: {
    type: "datetime",
    title: {
      text: "Date",
      style: {
        color: "#000000",
      },
    },
    crosshair: true,

    lineColor: "#000000",
    labels: {
      formatter: function () {
        return Highcharts.dateFormat("%b. %d, %Y", this.value);
      },
      style: {
        color: "#000000",
      },
    },
    accessibility: {
      description: "Date of Accessibility Tests",
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: "{point.y:.1f}%",
      },
    },
  },
  tooltip: {
    headerFormat: "",
    formatter: function () {
      return (
        "<b> Score:</b> " +
        this.y +
        "% " +
        "<b>Date:</b> " +
        Highcharts.dateFormat("%b. %d, %Y", new Date(this.x))
      );
    },
  },
  series: [
    {
      name: "[INSERT_COMPANY_HERE]",
      type: "line",
      data: [],
    },
  ],
  chart: {
    backgroundColor: "#fff",
    scrollablePlotArea: {
      minWidth: 700,
      scrollPositionX: 1,
    },
    style: {
      fontFamily: "Futura",
      color: "#000000",
    },
  },
};

const CompanyGraph = (props) => {
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.getCompanyDashboard.scopes.read
  });
  const [companies, setCompanies] = useState();
  useEffect(() => {
    if(!companies){
      execute("GET", protectedResources.getCompanyDashboard.endpoint + encodeURIComponent(props.company.replaceAll("%20", " ")), protectedResources.getCompanyDashboard.scopes).then((response) => {
        setCompanies(response);
      })
    }
  }, [execute, companies])
  if(companies){
  const avg = (companies.reduce((total, next) => total + next.testscore, 0)) / companies.length;

  //pre-processing of json data for graph
  const filteredCompanies = companies.map((company) => ({
    x: new Date(company.date),
    y: company.testscore,
  }));

  return (
    <div>
      <NumTests numTests={filteredCompanies.length} />
      <StyledCompanyGraph>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...options,
            series: [
              {
                name: `${props.company.replaceAll("%20", " ")}`,
                //colorByPoint: true,
                type: "line",
                data: filteredCompanies,
                color: "#000000",
                marker: {
                  fillColor: "#000000",
                  lineWidth: 2.5,
                  lineColor: "#000000", // inherit from series
                },
              },
            ],
            title: {
              align: "center",
              text: null,
            },
            yAxis: {
              title: {
                text: "Accessibility Score",
                style: {
                  color: "#000000",
                },
              },
              crosshair: true,
              accessibility: {
                description: "Accessibility score from 0 to 100 percent",
              },
              max: 100,
              labels: {
                format: "{value}%",
                style: {
                  color: "#000000",
                },
              },
              plotBands: [
                {
                  from: 40,
                  to: 60,
                  color: "#FF5C6C2B",
                },
                {
                  from: 60,
                  to: 85,
                  color: "#A79206",
                },
                {
                  from: 85,
                  to: 100,
                  color: "#2BA4912B",
                },
              ],
              plotLines: [
                {
                  color: "#000000",
                  width: 2,
                  value: avg,
                  dashStyle: "dash",
                  zIndex: 4,
                },
              ],
            },
            caption: {
              text: `<b>This chart indicates all of the accessibility test scores of ${props.company.replaceAll("%20", " ")}.</br>`,
            },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.1f}%",
                },
              },
            },
          }}
        />
      </StyledCompanyGraph>
      <Legend average={avg} />
    </div>
  );
};
};

export default CompanyGraph;
