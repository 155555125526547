import React, { useState, useEffect } from "react";
import { PureEditQuestionnaire } from "./EditQuestionnaire";
import { useLocation } from "react-router-dom";
import { protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";

const EditQuestionnaire = () => {
  const { execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.getAllCriteriaTypes.scopes.read,
    ],
  });

  let { state } = useLocation();
  const [criteriaTypeFields, setCriteriaTypeFields] = useState([]);
  const [answers, setAnswers] = useState([
    {
      criteriaid: null,
      feedback: "",
      feedbackid: null,
      reasoning: "",
      reasoningid: null,
      score: 5,
    },
    {
      criteriaid: null,
      feedback: "",
      feedbackid: null,
      reasoning: "",
      reasoningid: null,
      score: 4,
    },
    {
      criteriaid: null,
      feedback: "",
      feedbackid: null,
      reasoning: "",
      reasoningid: null,
      score: 2,
    },
    {
      criteriaid: null,
      feedback: "",
      feedbackid: null,
      reasoning: "",
      reasoningid: null,
      score: 0,
    },
    {
      criteriaid: null,
      feedback: "",
      feedbackid: null,
      reasoning: "",
      reasoningid: null,
      score: -1,
    },
  ]);

  const [criteria, setCriteria] = useState(
    state && state.criteria ? state.criteria :
      {
        alwaysapplies: false,
        capability: "",
        capabilityid: 1,
        criteria: "",
        criteriaid: null,
        criteriatypeid: 1,
        feedbackintro: "",
        rationale: "",
        testdescription: "",
      });

  const [criteriaType, setCriteriaType] = useState({});

  let [image, setImage] = useState({
    image: null,
    alttext: "",
  });

  let isNewQuestion = false;

  useEffect(() => {
    if (criteria.criteriatypeid) {
      execute(
        "GET", protectedResources.getCriteriaTypeById + criteria.criteriatypeid, protectedResources.getCriteriaTypeById.scopes).then((response) => {
        if (response) {
          setCriteriaType(response.length > 0 && response[0]);
        }
      });
    }
  }, [execute, criteria]);

  useEffect(() => {
    if (criteria.criteriaid) {
      execute(
        "GET",
        protectedResources.getReasoning.endpoint + criteria.criteriaid,
        protectedResources.getReasoning.scopes
      ).then((response) => {
        if (response) {
          setAnswers(response);
        }
      });
      execute(
        "GET",
        protectedResources.getImage.endpoint + criteria.criteriaid,
        protectedResources.getImage.scopes
      ).then((response) => {
        if (response) {
          let imageid = response[0].imageid;
          execute(
            "GET",
            protectedResources.addImage.endpoint + "/" + imageid,
            protectedResources.addImage.scopes
          ).then((response) => {
            const imageObj = response[0];
            const image = imageObj.image;
            const img = {
              ...imageObj,
              image: image,
            };
            setImage(img);
          });
        }
      });
    }
  }, [execute, criteria]);

  if (image && Object.keys(image).length === 0) {
    image = {
      image: null,
      alttext: "",
    };
  }

  if (state && state.isNewQuestion) {
    isNewQuestion = state.isNewQuestion;
  }

  const criteriaNum = criteria.criteriaid;

  useEffect(() => {
    execute("GET", protectedResources.getAllCriteriaTypes.endpoint, protectedResources.getAllCriteriaTypes.scopes).then(
      (response) => {
        if (response) {
          let tempFields = [];
          response.forEach((e) => {
            tempFields.push(e.criteriatype);
          });
          setCriteriaTypeFields(tempFields);
        }
      }
    );
  }, [execute, criteria]);

  const isUpload = false;

  const criteriaFields = [
    { name: "Criteria", type: "input" },
    { name: "Rationale", type: "input" },
    { name: "Visual Example", type: "image" },
    { name: "Alternate Image Text", type: "input" },
    {
      name: "Test Type",
      type: "dropdown",
      fields: criteriaTypeFields,
    },
    { name: "Description", type: "input" },
    { name: "Guidance Intro", type: "input" },
  ];

  const isSaving = false;
  const criteriaSize = criteria.length;

  return (
    <PureEditQuestionnaire
      criteriaNum={criteriaNum}
      criteria={criteria}
      criteriaType={criteriaType}
      criteriaSize={criteriaSize}
      isUpload={isUpload}
      criteriaFields={criteriaFields}
      image={image}
      isSaving={isSaving}
      answerProp={answers}
      isNewQuestion={isNewQuestion}
    />
  );
};

export default EditQuestionnaire;
