import styled from "styled-components"

export const TableElement = styled.th`
  padding: 20px 15px;
  text-align: center;

  :first-child {
    border-top-left-radius: 28px;
  }

  :last-child {
    border-top-right-radius: 28px;
  }
`

export const TableData = styled.td`
  padding: 8px;
`

export const StyledTable = styled.table`
  width: 100%;
  min-width: 800px;
  border-spacing: 0px;
  border-radius: 28px;
  border-color: transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07);

  @media print {
    box-shadow: none;
    border: 2px black solid;
  }

  thead {
    background-color: black;
    color: white;
  }
`

export const StyledFigure = styled.figure`
  margin: 0px;
  text-align: center;

  span.highlight {
    color: #FF5C6C;
    font-size: 0.5rem;
  }
`