import React, { useEffect } from "react";
import "./login-screen.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InteractionStatus } from "@azure/msal-browser";

const LoginScreen = () => {
  const navigate = useNavigate();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      navigate("/home");
    }
  });

  const onClickLogin = async (e) => {
    e.preventDefault();
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(loginRequest);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <div id="login-container">
        <img src={require("./bg7.jpg")} id="login-picture" alt="" />

        <div id="login-content-container">
          <form id="login-main-page-form">
            <h1 id="login-sub-title">Accessibility Indicators Test</h1>
            <div>
              <p>
              By logging into this application, you acknowledge 
              and agree that all information contained herein is 
              proprietary to Publicis Sapient. You commit not to 
              share, distribute, or disclose any of this information 
              with anyone outside of Publicis Sapient. Unauthorized 
              dissemination of this data is strictly prohibited.
              </p>
            </div>
            <button
              id="sign-in-button"
              type="submit"
              onClick={(e) => onClickLogin(e)}
            >
              Sign In with Lion ID
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
