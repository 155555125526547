import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility.js";
import { useNavigate } from "react-router-dom";
import * as styles from "./__styled__/DashboardGraph";
import DropDown from "../Dropdown/index";
import { sortCompanies, filterCompanies, filterByYear, filterByIndustry } from "./filterFunctions";
import Legend from "../Legend";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";

highchartsAccessibility(Highcharts);

const options = {
  title: {
    align: "center",
    text: "",
  },
  accessibility: {
    screenReaderSection: {
      beforeChartFormat:
        "<p>Column chart indicating the most recent accessibility scores of the top 50 PS clients, tab into the chart and use the left and right arrow keys to navigate the chart columns. Click or Press Enter on the chart columns to see every historical Accessibility Indicator score for that client.</p>",
    },
    announceNewData: {
      enabled: true,
    },
    point: {
      valueDescriptionFormat:
        "Company is {point.name}, test score is {point.y}%, test taken on {point.date}, company falls within {point.text} section.",
    },
    keyboardNavigation: {
      enabled: true,
    },
  },
  caption: {
    text: "<b>This chart indicates the most recent accessibility scores of the Publicis Sapient accounts.</br>",
  },
  xAxis: {
    type: "category",
    title: {
      text: "Accounts",
      style: {
        color: "#000000",
      },
    },
    lineColor: "#000000",
    accessibility: {
      description: "Accounts who have tested their websites accessibility",
    },
    labels: {
      style: {
        color: "#000000",
      },
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: "{point.y:.1f}%",
      },
    },
  },
  lang: {
    accessibility: {
      chartContainerLabel:
        "PS Clients Accessibility Indicators Scores, interactive chart",
    },
  },

  tooltip: {
    headerFormat: "",
    pointFormat:
      '<span style="color:{point.color}">Score</span>: <b>{point.y:.2f}</b>%<br/>',
  },
  series: [
    {
      name: "Accounts",
      type: "column",
      data: [],
    },
  ],
  chart: {
    backgroundColor: "#fff",
    scrollablePlotArea: {
      minWidth: 700,
      scrollPositionX: 1,
    },
    style: {
      fontFamily: "Futura",
      color: "#000000",
    },
  },
};

function setText(score) {
  if (score <= 100 && score >= 85) {
    return "Optimal range";
  }
  else if (score < 85 && score >= 60) {
    return "Easiest to improve";
  }
  else {
    return "Focus needed";
  }
}

const dateParse = (date) => {
  let parseDate = new Date(date);
  parseDate = new Date(parseDate.getTime() + parseDate.getTimezoneOffset() * 60000)
  const month = parseDate.toLocaleString('default', { month: 'short' });
  const day = parseDate.getDate();
  const year = parseDate.getFullYear();
  return (month + ". " + day + ", " + year);
};

const DashboardGraph = () => {
  const navigate = useNavigate();
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.getDashboard.scopes.read,
  });

  const [dashboardData, setDashboardData] = useState();
  let [filteredCompanies, setFilteredCompanies] = useState([]);
  let [allCompanies, setAllCompanies] = useState([]);
  let [avg, setAvg] = useState(0);
  let [sortVal, setSortVal] = useState(-1);
  let [filterVal, setFilterVal] = useState(-1);
  let [yearFilter, setYearFilter] = useState(-1);
  let [yearOptions, setYearOptions] = useState([]);
  let [industries, setIndustries] = useState();
  let [industryFilter, setIndustryFilter] = useState(-1);

  useEffect(() => {
    if(!dashboardData){
       execute("GET", protectedResources.getDashboard.endpoint, protectedResources.getDashboard.scopes).then((response) => {
        setDashboardData(response);
      });
    }

    if (!industries) {
      execute("GET", protectedResources.getIndustries.endpoint, protectedResources.getIndustries.scopes).then((response) => {
        setIndustries(response);
      });
    }
  }, [execute]);

  useEffect(() => {
    if (dashboardData) {
      const data = dashboardData;
      let companies = [];
      let average = 0;
      let years = ["No filter"];

      for (let i = 0; i < data.length; i++) {
        average += data[i].testscore;
        companies.push({
          text: setText(data[i].testscore),
          name: data[i].companyname,
          y: data[i].testscore,
          date: dateParse(data[i].date.slice(0, 10)),
          year: data[i].date.slice(0, 4),
          industryid: data[i].industryid
        });

        const year = data[i].date.slice(0, 4);
        if (!years.includes(year)) {
          years.push(year);
        }
      }

      setFilteredCompanies(companies);
      setAllCompanies(companies);
      setYearOptions(years);
      if (companies.length > 0) {
        setAvg(average / companies.length);
      }
    }
  }, [dashboardData])

  const navigateToCompany = (company) => {
    navigate('/company/' + company);
  };

  const updateFilteredCompanies = (val) => {
    let companies = allCompanies;

    if (val >= 0 && val <= 4) {
      setFilterVal(val);
      companies = filterCompanies(val, companies, yearFilter === -1 ? null : yearFilter);

      if (sortVal !== -1) {
        companies = sortCompanies(sortVal, companies);
      }

      if (yearFilter !== -1) {
        companies = filterByYear(yearFilter, companies);
      }
      if (industryFilter !== -1) {
        companies = filterByIndustry(industryFilter - 10, companies);
      }
    } else if (val >= 5 && val <= 9) {
      setSortVal(val);
      companies = sortCompanies(val, companies);

      if (filterVal !== -1) {
        companies = filterCompanies(filterVal, companies, yearFilter === -1 ? null : yearFilter);
      }

      if (yearFilter !== -1) {
        companies = filterByYear(yearFilter, companies);
      }

      if (industryFilter !== -1) {
        companies = filterByIndustry(industryFilter - 10, companies);
      }
    } else if (val >= 10) {
      setIndustryFilter(val);
      companies = filterByIndustry(val - 10, companies);

      if (filterVal !== -1) {
        companies = filterCompanies(filterVal, companies, yearFilter === -1 ? null : yearFilter);
      }

      if (yearFilter !== -1) {
        companies = filterByYear(yearFilter, companies);
      }

      if (sortVal !== -1) {
        companies = sortCompanies(sortVal, companies);
      }

    }

    setFilteredCompanies(companies);

    let avg = 0;

    if (companies.length > 0) {
      avg = (companies.reduce((total, next) => total + next.y, 0)) / companies.length;
    }

    setAvg(avg);
  };

  const updateFilteredYear = (year) => {
    let companies = allCompanies;
    companies = filterByYear(year, companies);

    if (filterVal !== -1) {
      companies = filterCompanies(filterVal, companies, year === "No filter" ? null : year);
    }

    if (sortVal !== -1) {
      companies = sortCompanies(sortVal, companies);
    }

    if (industryFilter !== -1) {
      companies = filterByIndustry(industryFilter - 10, companies);
    }

    setYearFilter(year);
    setFilteredCompanies(companies);

    let avg = 0;

    if (companies.length > 0) {
      avg = (companies.reduce((total, next) => total + next.y, 0)) / companies.length;
    }

    setAvg(avg);
  }

  return (
    <>
      <div aria-describedby="chart-instructions">
        <DropDown updateFilteredCompanies={updateFilteredCompanies} years={yearOptions} updateFilteredYear={updateFilteredYear} industries={industries}/>
        <styles.styledDashboardGraph>
          <div id="chart-instructions" className="sr-only">
            Use the arrow keys to cycle through the values in the chart
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...options,
              series: [
                {
                  name: "Accounts",
                  type: "column",
                  data: filteredCompanies,
                  point: {
                    events: {
                      //navigates to company with click or enter key
                      click: function (event) {
                        if (event.target.name === undefined) {
                          navigateToCompany(event.point.name);
                        } else {
                          navigateToCompany(event.target.name);
                        }
                      },
                    },
                  },
                },
              ],
              tooltip: {
                headerFormat: "",
                pointFormat:
                  '<span>{point.name}</span><br/><span>Score</span>: <b>{point.y:.2f}</b>%<br/><span>Date</span>: <b>{point.date}</b><br/><span style="color:{point.color}">■</span><b> {point.text}</b>',
              },
              yAxis: {
                title: {
                  text: "Accessibility Score",
                  style: {
                    color: "#000000",
                  },
                },
                accessibility: {
                  description: "Accessibility score from 0 to 100 percent",
                },
                max: 100,
                labels: {
                  format: "{value}%",
                  style: {
                    color: "#000000",
                  },
                },
                plotLines: [
                  {
                    color: "#000000",
                    width: 2,
                    value: avg,
                    dashStyle: "dash",
                    zIndex: 2,
                  },
                ],
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: true,
                    format: "{point.y:.0f}%",
                  },
                  // colours the bar graph depending on range of score (x value) 
                  // value represents non-inclusive upper bound for the associated colour
                  zones: [
                    {
                      value: 60,
                      color: "#FF5C6C",
                    },
                    {
                      value: 85,
                      color: "#A79206",
                    },
                    {
                      value: 101,
                      color: "#2BA491",
                    },
                    {
                      color: "#90ed7d",
                    },
                  ],
                },
                column: {
                  zones: [
                    {
                      value: 60,
                      color: "#FF5C6C",
                    },
                    {
                      value: 85,
                      color: "#A79206",
                    },
                    {
                      value: 101,
                      color: "#2BA491",
                    },
                    { color: "#000000" },
                  ],
                },
              },
              chart: {
                backgroundColor: "#fff",
                scrollablePlotArea: {
                  minWidth: 700,
                  scrollPositionX: 1,
                },
                style: {
                  fontFamily: "Futura",
                  color: "#000000",
                },
                events: {
                  render: function (event) {
                    if (event.target.series[0]?.data?.length > 0) {
                      const data = event.target.series[0]?.data;
                      for (let d of data) {
                        d.graphic?.element?.setAttribute("aria-describedby", "chart-instructions");
                      }
                    }
                  }
                }
              },
            }}
          />
        </styles.styledDashboardGraph>
      </div>
      <div>
        <Legend average={avg} />
      </div>
    </>
  );
};

export default DashboardGraph;
