// This is the types of test that apply based on the question.
// it's indexed based on the page number.
// Refer to the api_slugs.txt for a list of all the tests run by the Pope Tech service.
import { getTotalIssues, getPages } from "../utilities/api";
import { getItem } from "../utilities/storage";
import { protectedResources } from "../authConfig";

export const TestMap = new Map();

const getTotal = (results, testId) => {
    let total = 0;
    let totalPages = getItem(testId)?.pages.length;
    results.map((test) => {
        total += test.count;
    });
    return Math.ceil(total/totalPages);
}

// set the return value based on page Id.
// 2: Videos
TestMap.set(2, 
    {  
        tests: {
            errors: null, 
            alerts: ["youtube_video", "html5_video_or_audio", "audiovideo"],
            contrast: null
        },
        output: (results) => {
            if(!results.length) {
                return { answer: 'opt4', notes: 'WAVE: No videos found on test pages', capability: 'NA'};
            }
            return null;
        }
    }
)
// 6: Contrast
TestMap.set(6, 
    {  
        tests: {
            errors: null, 
            alerts: null,
            contrast: ["very_low_contrast"]
        },
        output: (results, testId) => {
            let total = getTotal(results, testId);
            let ret = null;
            switch(true) {
                case (total > 0 && total < 4):
                    ret = { answer: 'opt1', notes: 'WAVE: 1-3 contrast issues found.', capability: 'VisualDesign'};
                    break;
                case (total > 3 && total < 11):
                    ret = { answer: 'opt2', notes: 'WAVE: 4-10 contrast issues found.', capability: 'VisualDesign'};
                    break;
                case (total > 10):
                    ret = { answer: 'opt3', notes: 'WAVE: More than 10 contrast issues found.', capability: 'VisualDesign'};
                    break;
                default:
                    ret = { answer: 'opt0', notes: 'WAVE: No issues found', capability: 'NA'};
            }

            return ret;
        }
    }
)

// 7: Landmarks
TestMap.set(7, 
    {  
        tests: {
            errors: null, 
            alerts: ["no_page_regions"],
            contrast: null
        },
        output: (results) => {
            if(results.length) {
                return { answer: 'opt3', notes: 'WAVE: No landmarks or regions found on the page.', capability: 'XT'};
            }
            return null;
        }
    }
)

// 8: Heading Structure
TestMap.set(8, 
    {  
        tests: {
            errors: ["empty_heading"], 
            alerts: ["possible_heading", "skipped_heading_level", "missing_first_level_heading", "no_heading_structure"],
            contrast: null
        },
        output: (results, testId) => {
            let total = (results.length > 0) ? getTotal(results, testId) : 0;
            let ret = null;

            switch (true) {
                case (total > 0 && total < 3):
                    ret = { answer: 'opt1', notes: 'WAVE: Between 1 and 2 heading issues found.', capability: 'XT'};
                    break;
                case (total > 2 && total < 5):
                    ret = { answer: 'opt2', notes: 'WAVE: Between 3 and 4 heading issues found.', capability: 'XT'};
                    break;
                case (total > 4):
                    ret = { answer: 'opt3', notes: 'WAVE: More than 5 heading issues found.', capability: 'XT'};
                    break;
                default:
                    ret = { answer: 'opt0', notes: 'WAVE: No issues found', capability: 'NA'};
            }

            return ret;
        }
    }
)

// 9: Alt text
TestMap.set(9, 
    {  
        tests: {
            errors: ["missing_alternative_text", "linked_image_missing_alternative_text"], 
            alerts: ["suspicious_alternative_text", "redundant_alternative_text", "long_alternative_text"],
            contrast: null
        },
        output: (results, testId) => {
            let total = (results.length > 0) ? getTotal(results, testId) : 0;
            let ret = null;

            switch (true) {
                case (total > 0 && total < 4):
                    ret = { answer: 'opt1', notes: 'WAVE: Between 1 and 3 images with alt text issues found.', capability: 'XT'};
                    break;
                case (total > 3 && total < 11):
                    ret = { answer: 'opt2', notes: 'WAVE: Between 4 and 10 images with alt text issues found.', capability: 'XT'};
                    break;
                case (total > 10):
                    ret = { answer: 'opt3', notes: 'WAVE: More than 10 images with alt text issues found.', capability: 'XT'};
                    break;
                default:
                    ret = { answer: 'opt0', notes: 'WAVE: No images with alt text found', capability: 'NA'};
            }

            return ret;
        }
    }
)

// 10: Button labels
TestMap.set(10, 
    {  
        tests: {
            errors: ["empty_link", "linked_image_missing_alternative_text", "empty_button", "image_button_missing_alternative_text"], 
            alerts: ["suspicious_link_text"],
            contrast: null
        },
        output: (results, testId) => {
            let total = (results.length > 0) ? getTotal(results, testId) : 0;
            let ret = null;

            switch (true) {
                case (total > 0 && total < 4):
                    ret = { answer: 'opt1', notes: 'WAVE: Between 1 and 3 button/link issues found.', capability: 'XT'};
                    break;
                case (total > 3 && total < 7):
                    ret = { answer: 'opt2', notes: 'WAVE: Between 4 and 6 button/link issues found.', capability: 'XT'};
                    break;
                case (total > 6):
                    ret = { answer: 'opt3', notes: 'WAVE: More than 6 button/link issues found.', capability: 'XT'};
                    break;
                default:
                    ret = { answer: 'opt0', notes: 'WAVE: No empty buttons, empty links or suspicious links found', capability: 'NA'};
            }

            return ret;
        }
    }
)

// 11: WAVE Errors
TestMap.set(11, 
    {  
        tests: (testId) => {
            return getTotalIssues(getItem(testId).website_id).then(resp => {
                return [{name: 'Total WAVE errors', count: resp.data.results.errors.total}];
            });
        },
        output: (results, testId) => {
            let totalPages = getItem(testId).pages.length;
            let avg = Math.round(results[0].count/totalPages)
            let opt = (avg <= 3 ) ? 'opt0' : 'opt1';
            return { answer: opt, notes: `WAVE: Total errors: ${results[0].count}, Total pages: ${totalPages}, Average errors ${avg}`, capability: 'XT' };
        }
    }
)

// 12: Lighthouse Score
// TestMap.set(12, 
//     {  
//         tests: (testId) => {
//             let score = 0;
//             let totalPages = getItem(testId).pages.length;
//             let results = getItem(testId).lighthouse;
//             for ( let i = 0; i < results.length; i++ ) {
//                 score += results[i].score;
//             }
//             let avg = Math.round(score * 100/totalPages);
//             return new Promise((res) => { res(); }).then(() => {
//                 return [{name: 'Lighthouse score', count: avg}];
//             })
//         },
//         output: (results, testId) => {
//             let totalPages = getItem(testId).pages.length;
//             let opt = (results[0].count <= 89 ) ? 'opt1' : 'opt0';
//             return { answer: opt, notes: `Lighthouse: Total pages: ${totalPages}, Average score: ${results[0].count}%`, capability: 'XT' };
//         }
//     }
// )

// 13: Page Titles
TestMap.set(13, 
    {  
        tests: (testId) => {
            return getPages(getItem(testId).scan_id).then(resp => {
                return resp.data.map(page => {
                    return { name: page.page.title };
                })
            });
        },
        output: (results) => {
            let titles = results.map(title => {
                return `\n${title.name}`;
            })
            let hasPipe = results.map(title => {
                return title.name.indexOf('|') > -1;
            }).includes(true);

            let sameTitles = results.every( (val, i, arr) => val === arr[0] );
            let ret = {};

            switch(true) {
                case(sameTitles):
                    ret = { answer: 'opt3', notes: `WAVE: All the titles are the same.`, capability: 'Copy' };
                    break;
                case(hasPipe):
                    ret = { answer: 'opt1', notes: `WAVE: Titles contain a pipe (|) character. ${titles.toString()} `, capability: 'Copy' };
                    break;
                default:
                    ret = { answer: 'opt0', notes: `WAVE: No issues found`, capability: 'NA' };
            }

            return ret;
        }   
    }
)

// 14: Pinch/Zoom
// TestMap.set(14, 
//     {  
//         tests: (testId,execute) => {
//             let options = {
//                 file: getItem(testId).lighthouse[0].file,
//                 audit: 'meta-viewport'
//             }
//             return execute("POST", protectedResources.addLighthouse.endpoint + '/audits/' + testId, protectedResources.addLighthouse.scopes, options).then(
//                 (resp) => { 
//                     let res = JSON.parse(resp);
//                     let passFail = res.score === 1 ? 'pass' : 'fail';
//                     return [{name: 'Lighthouse: meta-viewport', count: passFail, title: res.title}]; 
//                 }
//             );
//         },
//         output: (results) => {
//             let ret = { answer: 'opt1', notes: `Lighthouse: meta-viewport failed test. ${results[0].title}`, capability: 'NA'};
//             if(results[0].count === 'pass') {
//                 ret = { answer: 'opt0', notes: `Lighthouse: meta-viewport passed test. ${results[0].title}`};
//             }

//             return ret;
//         }
//     }
// )

// 16: Skip Links
TestMap.set(16, 
    {  
        tests: {
            errors: ["broken_skip_link"], 
            alerts: ["broken_same_page_link", "javascript_jump_menu"],
            contrast: null
        },
        output: (results) => {
            let ret = { answer: 'opt0', notes: 'WAVE: Skip link is working (NEEDS VALIDATION)', capability: 'NA'};
            if(results[0]?.slug === 'broken_skip_link') {
                ret = { answer: 'opt3', notes: 'WAVE: Broken skip link found on the page', capability: 'XT'};
            }

            return ret;
        }
    }
)

// 18: Forms
TestMap.set(18, 
    {  
        tests: {
            errors: ["missing_form_label", "multiple_form_labels", "empty_form_label"], 
            alerts: ["unlabeled_form_element_with_title", "orphaned_form_label", "fieldset_missing_legend", "unlabeled_form_control_with_title"],
            contrast: null
        },
        output: (results) => {
            let total = getTotal(results);
            let ret = {};
            switch(true) {
                case (total > 0 && total < 4):
                    ret = { answer: 'opt2', notes: 'WAVE: Issues with 1-3 form labels.', capability: 'XT'};
                    break;
                case (total > 3):
                    ret = { answer: 'opt3', notes: 'WAVE: Many issues found with form labels.', capability: 'XT'};
                    break;
                default:
                    ret = { answer: 'opt0', notes: 'WAVE: No issues with form labels.', capability: 'NA'};
            }

            return ret;
        }
    }
)

// 20: Roles
TestMap.set(20, 
    {  
        tests: {
            errors: ["broken_aria_reference", "broken_aria_menu"], 
            alerts: ["broken_same_page_link"],
            contrast: null
        },
        output: (results) => {
            
            if(results.length) {
                return { answer: 'opt2', notes: 'WAVE: Possible links used as buttons', capability: 'XT'};
            }
            return null;
        }
    }
)

/* Sample results
{
    "alerts": [
        {
            "name": "Redundant link",
            "slug": "redundant_link",
            "websites": 1,
            "count": 456
        },
        {
            "name": "Suspicious link text",
            "slug": "suspicious_link_text",
            "websites": 1,
            "count": 12
        },
        {
            "name": "Orphaned form label",
            "slug": "orphaned_form_label",
            "websites": 1,
            "count": 4
        },
        {
            "name": "Missing first level heading",
            "slug": "missing_first_level_heading",
            "websites": 1,
            "count": 4
        }
    ],
    "errors": [
        {
            "name": "Empty link",
            "slug": "empty_link",
            "websites": 1,
            "count": 24
        },
        {
            "name": "Empty button",
            "slug": "empty_button",
            "websites": 1,
            "count": 16
        },
        {
            "name": "Empty form label",
            "slug": "empty_form_label",
            "websites": 1,
            "count": 8
        },
        {
            "name": "Missing alternative text",
            "slug": "missing_alternative_text",
            "websites": 1,
            "count": 8
        },
        {
            "name": "Missing form label",
            "slug": "missing_form_label",
            "websites": 1,
            "count": 4
        },
        {
            "name": "Broken ARIA reference",
            "slug": "broken_aria_reference",
            "websites": 1,
            "count": 4
        }
    ],
    "features": [
        {
            "name": "Alternative text",
            "slug": "alternative_text",
            "websites": 1,
            "count": 28
        },
        {
            "name": "Linked image with alternative text",
            "slug": "linked_image_with_alternative_text",
            "websites": 1,
            "count": 24
        },
        {
            "name": "Form label",
            "slug": "form_label",
            "websites": 1,
            "count": 20
        },
        {
            "name": "Language",
            "slug": "language",
            "websites": 1,
            "count": 4
        }
    ],
    "aria": [
        {
            "name": "ARIA description",
            "slug": "aria_description",
            "websites": 1,
            "count": 20
        },
        {
            "name": "ARIA label",
            "slug": "aria_label",
            "websites": 1,
            "count": 16
        },
        {
            "name": "ARIA tabindex",
            "slug": "aria_tabindex",
            "websites": 1,
            "count": 16
        },
        {
            "name": "ARIA",
            "slug": "aria",
            "websites": 1,
            "count": 4
        }
    ],
    "structural": [
        {
            "name": "Unordered list",
            "slug": "unordered_list",
            "websites": 1,
            "count": 132
        },
        {
            "name": "Navigation",
            "slug": "navigation",
            "websites": 1,
            "count": 92
        },
        {
            "name": "Heading level 3",
            "slug": "heading_level_3",
            "websites": 1,
            "count": 60
        },
        {
            "name": "Heading level 2",
            "slug": "heading_level_2",
            "websites": 1,
            "count": 16
        },
        {
            "name": "Footer",
            "slug": "footer",
            "websites": 1,
            "count": 4
        },
        {
            "name": "Header",
            "slug": "header",
            "websites": 1,
            "count": 4
        },
        {
            "name": "Heading level 4",
            "slug": "heading_level_4",
            "websites": 1,
            "count": 4
        }
    ]
}
*/