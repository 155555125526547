import accessibility from './accessibility.module.js';

//small navbar
function smaller() {
    let navbar = document.getElementById("navbar");
    var links = document.getElementById("navbar-right");
    var title = document.getElementById("sitetitle");
    var btn = document.getElementById("drawerbtnup");
    var navlogo = document.getElementById("navlogo");

    if (links) {
        links.style.display = "none";
    }
    if (window.innerWidth <= 1330 && btn) {
        btn.innerHTML = "&#65086;";
        btn.style.marginTop = "-26px";
        btn.style.padding = "1rem 2rem 0.2rem";
    } 
    if (navbar) {
        navbar.style.height = "0.3%";
    }
    if (title) {
        title.style.float = "left";
        title.style.marginTop = "-10px";
        title.style.fontSize = "1.3rem";
    }
    if (navlogo) {
        navlogo.style.padding = "0.2rem 1.5rem";
        navlogo.style.transition = "0.4s";
    }
}

//expanded navbar
function larger() {
    let navbar = document.getElementById("navbar");
    var links = document.getElementById("navbar-right");
    var title = document.getElementById("sitetitle");
    var btn = document.getElementById("drawerbtnup");
    var navlogo = document.getElementById("navlogo");

    if (links) {
        links.style.display = "block";
    }
    if (navbar) {
        navbar.style.overflow = "hidden";
    }
    if (window.innerWidth <= 1330) {
        if (btn) {
            btn.innerHTML = "&#65085;";
            btn.style.marginTop = "-30px";
            btn.style.padding = "1.2rem 2rem 2rem";
        }
        if (navlogo) {
            navlogo.style.padding = "1rem 1.5rem";
        }
    } else if (navlogo) {
        navlogo.style.padding = "1.2rem 1.5rem";
    }
    if (title) {
        title.style.fontSize = "1.7rem";
        title.style.marginTop = "0px";
    }
    if (navbar) {
        navbar.style.height = "2rem";
    }
    if (navlogo) {
        navlogo.style.transition = "0.4s";
    }
}

//checks for mobile/small window
window.onload = function () {
    if (window.innerWidth <= 1330) {
        smaller();
    } else {
        larger();
    }
};

//minimizes on scroll
window.onscroll = function () {
    if (window.innerWidth > 1330) {
        if (document.documentElement.scrollTop < 1) {
            larger();
        } else {
            smaller();
        }
    }
};

//manual minimizing
const minimize = (menuExpanded) => {
    let navbar = document.getElementById("navbar");

    if (menuExpanded) {
        larger();

        if (window.innerWidth <= 1330 && navbar) {
            navbar.style.overflow = "visible";
            accessibility.setKeepFocusInside(navbar, true);
        }
    }

    else {
        accessibility.setKeepFocusInside(navbar, false);

        if (window.innerWidth <= 1330 && navbar) {
            navbar.style.overflow = "hidden";
        }

        smaller();
    }
}

export default minimize;