import React, { useState, useEffect, useRef } from "react";
import "./add-pages-screen.css";
import "../Scoring_Screen/scoringscreen.css";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { loginRequest, protectedResources } from "../../authConfig";
import { addPage, deletePage, cancelScan } from "../../utilities/api";
import { getItem, updateItem } from "../../utilities/storage";
import { ScanModal } from "../ScanModal/scan-modal";

const AddPagesScreen = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const dialogRef = useRef(null);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const pages = [];
  const [pageURLs, addNewPage] = useState(pages);
  const [showModal, setShowModal] = useState(false);
  const { error, execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.getTestVersions.scopes.read,
      ...protectedResources.getDashboard.scopes.read,
      ...protectedResources.addUser.scopes.write,
      ...protectedResources.addPopeTech.scopes.write,
    ],
  });
  const location = useLocation();
  const [LHStatus, setLHStatus] = useState("Started");
  const { state } = location;
  const testId = state?.testId || new URLSearchParams(location.search).get("testId");

  const getData = async () => {
    execute("GET", protectedResources.getTestVersions.endpoint, protectedResources.getTestVersions.scopes).then(
      (response) => {
        return response;
      }
    );
    execute("GET", protectedResources.getDashboard.endpoint, protectedResources.getDashboard.scopes).then(
      (response) => {
        return response;
      }
    );
  };

  useEffect(() => {
    getData().then((response) => { });
    const body = {
      userId: account.localAccountId
    };
    execute("POST", protectedResources.addUser.endpoint, protectedResources.addUser.scopes, body)
  }, [execute]);

  useEffect(() => {
    if (showModal) {
      dialogRef.current.showModal();
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      dialogRef.current?.close();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [showModal]);

  useEffect(() => {
    const savedPages = getItem(testId)?.pages;
    if (savedPages) {
      addNewPage(savedPages);
    }
  },[]);

  const handleBeforeUnload =(e) => {
    if(showModal) {
      e.preventDefault();
      e.returnValue = true;
      cancelScan(getItem(testId).scan_id);
    }
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    let urlString = inputRef.current.value;

    addPage(testId, urlString).then((resp) => {
      let page = {
        url: resp.data.full_url,
        id: resp.data.public_id
      };
      addNewPage([
        ...pageURLs,
        page
      ]);
      inputRef.current.value = "";
    }).catch(() => {
      alert('Page not added to Pope Tech');
    })
  }

  const handleDeleteItem = (id, index) => {
    let pageCopy = pageURLs.toSpliced(index);
    addNewPage(pageCopy);
    deletePage(testId, id);
  }

  const handleNextClick = () => {
    if (pageURLs.length <= 1) {
      alert('You need to add at least 2 pages before you can run a scan.');
      return;
    }
    lighthouseScan();
    // setLHStatus("Completed"); //remove after LH is fixed.
    updateItem(testId, { pages: pageURLs });
    execute("PUT", protectedResources.updatePopeTechPages.endpoint + testId, protectedResources.updatePopeTechPages.scopes, { pages: JSON.stringify(pageURLs) });
    setShowModal(true);
  }

  const lighthouseScan = () => {
    let urls = pageURLs.map(page => page.url).toString();
    const lighthouseData = {
      pages: urls,
      testid: testId,
    };

    try {
      execute("POST", protectedResources.addLighthouse.endpoint, protectedResources.addLighthouse.scopes, lighthouseData).then(resp => {
        let json = JSON.parse(resp);
        setLHStatus("Completed");
        updateItem(testId, { lighthouse: json });
      });
    } catch (err) {
      setLHStatus("Error");
      //updateItem(testId, { lighthouse: {} });
    }
    
  }

  return (
    <>
      <AuthenticatedTemplate
        username={account.username}
        localAccountId={account.localAccountId}
        homeAccountId={account.homeAccountId}
      >
        <Helmet>
          <title>Add Pages</title>
        </Helmet>

        <div>
          <h1
            className="page-header"
          >Add Pages for Scanning</h1>

          <div className="biform">
            <p>These pages are required for the Pope Tech platform to scan for accessibility issues. </p>
            <form>
              <label htmlFor="addPage">Page URL</label>
              <input id="addPage" name="addPage" type="text" ref={inputRef}></input>
              <button className="addPageBtn" onClick={handleAddClick}>Add page</button>
            </form>

            <section className="table-section">
              <h2>Pages Added</h2>
              <div className="pages table-container">

                <table className="dark">
                  <caption className="sr-only">Pages Added</caption>
                  <thead>
                    <tr>
                      <th scope="col">URLs</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageURLs?.map((item, index) => {
                      return <tr key={item.id}>
                        <th scope="row" id={item.id}>{item.url}</th>
                        <td><button className="btn-inline" onClick={() => handleDeleteItem(item.id, index)} aria-labelledby={item.id}>Delete</button></td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
            </section>

            <div className="nav">
              <input
                type="button"
                className="btn"
                name="prevButton"
                onClick={() => navigate("/BasicInfo")}
                value="Previous"
                readOnly
              />
              <input
                type="button"
                className="btn"
                value="Start Scan"
                aria-haspopup="true"
                onClick={handleNextClick}
                readOnly
              />
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      {showModal && (<ScanModal modalRef={dialogRef} modalControl={setShowModal} lighthouse={LHStatus}></ScanModal>)}
    </>
  );
};

export default AddPagesScreen;
