import {
  LegendStyle,
  LegendText,
  LegendParaFormat,
  BoldedText,
  ColourDiv,
} from "./__styled__/Legend";

const Legend = (props) => {
  return (
    <div>
      <LegendStyle aria-label="legend to interpret scores">
        <LegendText>
          <span className="sr-only">{`Average Score is ${props.average} percent`}</span>
          <LegendParaFormat aria-hidden="true">
            Average <BoldedText>{props.average.toFixed(1)}%</BoldedText>
          </LegendParaFormat>
        </LegendText>
        <LegendText>
          <span className="sr-only">Pink bars denote focus needed for companies with scores below 60 percent</span>
          <LegendParaFormat aria-hidden="true">
            <ColourDiv style={{ backgroundColor: "#FF5C6C" }}></ColourDiv>
            Focus needed <BoldedText>0 - 59.9%</BoldedText>
          </LegendParaFormat>
        </LegendText>
        <LegendText>
          <span className="sr-only">Yellow bars denote the easiest companies to improve, where the score lies between 60 to 84.9 percent</span>
          <LegendParaFormat aria-hidden="true">
            <ColourDiv style={{ backgroundColor: "#A79206" }}></ColourDiv>
            Easiest to improve <BoldedText>60 - 84.9%</BoldedText>
          </LegendParaFormat>
        </LegendText>

        <LegendText>
          <span className="sr-only">Green bars denote the optimal range of scores between 85 to 100%</span>
          <LegendParaFormat aria-hidden="true">
            <ColourDiv style={{ backgroundColor: "#2BA491" }}></ColourDiv>
            Optimal range <BoldedText>85 - 100%</BoldedText>
          </LegendParaFormat>
        </LegendText>
      </LegendStyle>
    </div>
  );
};

export default Legend;
