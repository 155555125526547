import { StyledDiv, WrapperDiv } from "./__styled__/NumTests";

const NumTests = (props) => {
  return (
    <WrapperDiv>
      <StyledDiv>
        <p>
          Number of tests <b>{props.numTests}</b>
        </p>
      </StyledDiv>
    </WrapperDiv>
  );
};

export default NumTests;
