import * as React from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import './buttonStyle.css';

export default function BackButtonReport(companyName) {
  return (
    <div className='back-button-container'>
      <Link to={`/company/${companyName.companyName}`}>
        <button
          className="back-button"
          type="button"
          autoFocus={true}
          aria-label='Back to Account Specific'
        >
          <FaArrowLeft />  Account Specific
        </button>
      </Link>
    </div>

  );
}