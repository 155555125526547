import React from "react"
import { PureAnswerTable } from "./AnswerTable"

const AnswerTable = ({ answersProp, shouldShowErrors}) => {
  
  const answerFields = ["Points", "Label", "Guidance"]

  const handleOnLabelInputChange = (value, idx) => {
    answersProp[idx].reasoning = value;
  }

  const handleOnGuidanceInputChange = (value, idx) => {
    answersProp[idx].feedback = value;
  }

  return (
    <PureAnswerTable
      answers={answersProp}
      answerFields={answerFields}
      onLabelInputChange={handleOnLabelInputChange}
      onGuidanceInputChange={handleOnGuidanceInputChange}
      shouldShowErrors={shouldShowErrors}
    />
  )
}

export default AnswerTable
