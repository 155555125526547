'use strict'


/*******************************************************************************
* sortable-table.js - A sortable table script
* 
* This has been mofified from the excellent script from Deque University:
* https://dequeuniversity.com/library/aria/table-sortable
* and the enable-a11y project.
*
*******************************************************************************/


const sortableTables = new (function () {
 /*
  * sortableTable script.  This has been mofified from the excellent
  * script from Deque University:
  * https://dequeuniversity.com/library/aria/table-sortable
  * and the enable-a11y project.
  */
  this.functions = {};

  this.add = (tableGroup, options) => {
    var table = tableGroup.querySelector("table");
    var headerGroup = table.querySelector("thead");
    var headerRow = headerGroup.querySelector("tr");
    var headers = headerRow.querySelectorAll("th");
    var rowGroup = table.querySelector("tbody");
    var rows = rowGroup.querySelectorAll("tr");
    var captionElement = table.querySelector("caption") || document.getElementById(table.getAttribute('aria-labelledby'));
    var captionDetailsElement = captionElement.querySelector('.deque-table-sortable__caption-details')
    var caption = captionElement.innerText;
    var hasRowHeadings = (table.querySelector('tbody th') !== null);
    var ariaLiveUpdateTemplate =
      table.getAttribute("data-aria-live-update") ||
      "Table ${caption} is now ${sortedBy}";
    var ascendingLabel =
      table.getAttribute("data-ascending-label") || "ascending";
    var descendingLabel =
      table.getAttribute("data-descending-label") || "descending";

    var liveRegion = tableGroup.querySelector(
      ".deque-table-sortable__live-region"
    );
    var readCaptions = liveRegion.getAttribute("data-read-captions");

    if (readCaptions === null) {
      readCaptions = false;
    }
    liveRegion.notify = function (text) {
      liveRegion.innerHTML = text;
    };

    table.options = options;

    var sortOrder = null;
    var sortDirection = 1;

    function getSortHeader() {
      if (sortOrder === null) {
        return null;
      }
      return headerRow.children[sortOrder];
    }

    function getSortLabel() {
      var header = getSortHeader();
      if (!header) {
        return null;
      }
      return header.innerText;
    }

    function getSortDirection() {
      return sortDirection > 0 ? "ascending" : "descending";
    }

    function getSortDirectionLabel() {
      return sortDirection > 0 ? ascendingLabel : descendingLabel;
    }

    function getSortInfo() {
      if (sortOrder === null) {
        return "unsorted";
      }

      return "sorted by " + getSortLabel() + ", " + getSortDirectionLabel();
    }

    function renderSorting() {
      updateCaption();
      updateAriaSort();
      updateLiveRegion();
    }

    function updateAriaSort() {
      for (var i = 0; i < headerRow.children.length; i++) {
        var child = headerRow.children[i];
        var button = child.querySelector('.sortableColumnLabel');

        if (sortOrder !== null && i === Math.abs(sortOrder)) {
          var direction = getSortDirection();
          var directionLabel = getSortDirectionLabel();
          child.setAttribute("aria-sort", direction);
          button.setAttribute("aria-label", `${button.innerText}, ${directionLabel}`);
        } else {
          child.removeAttribute("aria-sort");
          button.removeAttribute("aria-label");
        }
      }
    }

    function updateCaption() {
      if (!captionDetailsElement) {
        return;
      }
      var captionDetailsText = getSortInfo();
      captionDetailsElement.innerText = captionDetailsText;
    }

    function updateLiveRegion() {
      if (readCaptions) {
        var captionText = ariaLiveUpdateTemplate
          .replace("${caption}", caption)
          .replace("${sortedBy}", getSortInfo());
        liveRegion.notify(captionText);
      }
    }

    rows = Array.prototype.slice.call(rows);
    var isValid = rows.every(function (row) {
      return row.children.length === headers.length;
    });

    if (!isValid) {
      throw new Error("Each row must be the same length as the headers row.");
    }

    headers = Array.prototype.slice.call(headers);
    [].slice.call(headers).forEach(function (header, i) {
      //implements header functionality to sort when clicked
      createHeaderCell(header, function (e) {
        const { options } = table;

        e.preventDefault();

        if (options && options.onBeforeSort) {
          options.onBeforeSort(table);
        }
        
        if (options && options.onAfterSort) {
          options.onAfterSort(table);
        }

        sortDirection = -sortDirection;
        rows = sortByIndex(rows, i);
        table.renderData(rows);
      });
    });

    //re-rendering table data
    table.renderData = function (rows) {
      rowGroup.innerHTML = '<tbody></tbody>';
      for(let i=0; i<rows.length;i++){
        rowGroup.appendChild(rows[i])
      }
      renderSorting();
    };

    table.renderData(rows);

    //sortable functionality
    const sortByIndex = (rows, index) => {
      const tableHeadings = tableGroup.querySelectorAll('thead th');
      const ascending =  (function (a, b) {
        a = Array.prototype.slice.call(a.children);
        b = Array.prototype.slice.call(b.children);
        var aVal = null;
        var bVal = null;
  
      
        if (a[index]) {
          aVal = a[index].innerText;
        }
  
        if (b[index]) {
          bVal = b[index].innerText;
        }
  
        if(tableHeadings[index].textContent == "Date of Most Recent Test"){
          if (new Date(aVal) < new Date(bVal)){
            return -1;
          }else if (new Date(aVal) > new Date(bVal)){
            return 1;
          } 
          return 0;
        }else if (!isNaN(parseInt(aVal)) && !isNaN(parseInt(bVal))) {
          if (parseInt(aVal) < parseInt(bVal)) {
            return -1;
          }
          if (parseInt(aVal) > parseInt(bVal)) {
            return 1;
          }
          return 0;
        } else {
          if (aVal < bVal) {
            return 1;
          }
          if (aVal > bVal) {
            return -1;
          }
          return 0;
        }
      });

      const descending =  (function (a, b) {
        a = Array.prototype.slice.call(a.children);
        b = Array.prototype.slice.call(b.children);
        var aVal = null;
        var bVal = null;
  
      
        if (a[index]) {
          aVal = a[index].innerText;
        }
  
        if (b[index]) {
          bVal = b[index].innerText;
        }
  
        if(tableHeadings[index].textContent == "Date of Most Recent Test"){
          if (new Date(aVal) < new Date(bVal)){
            return 1;
          }else if (new Date(aVal) > new Date(bVal)){
            return -1;
          } 
          return 0;
        }else if (!isNaN(parseInt(aVal)) && !isNaN(parseInt(bVal))) {
          if (parseInt(aVal) < parseInt(bVal)) {
            return 1;
          }
          if (parseInt(aVal) > parseInt(bVal)) {
            return -1;
          }
          return 0;
        } else {
          if (aVal < bVal) {
            return -1;
          }
          if (aVal > bVal) {
            return 1;
          }
          return 0;
        }
      });

      rows = tableGroup.querySelectorAll("tbody tr");
      rows = [].slice.call(rows);
      sortOrder = index;

      if (sortDirection === 1) {
        return rows.sort(ascending);
      } else {
        return rows.sort(descending);
      }
    }

    var firstOne = table.querySelector(".sortableColumnLabel");
    if (firstOne) {
      firstOne.click();
    } // give the table a default sort...
  }

  //links sortable functionality to clickable headers
  function createHeaderCell(header, handler) {
    var button = header.querySelector("button");
    //additional check to allow for non-sortable columns
    if(button!= null){
      button.setAttribute("tabindex", "0");
      button.addEventListener("click", handler);
    }
  }
  
  this.init = (options) => {
    var companyTable = document.getElementById("overview-sortable-table")
    this.add(companyTable, options)
  }
})();

export default sortableTables;