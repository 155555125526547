import styled from "styled-components";

export const styledDashboardGraph = styled.div`
  margin: 1em;

  @media print {
    @page {size: landscape}
    overflow: visible !important;
    position: static !important;
    width: fit-content;
  }
`;
export const pageHeader = styled.h1`
  font-family: "Futura";
`;

export const RoundedDiv = styled.div`
  border-radius: 28px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07);
  overflow-x: auto;

  @media print {
    box-shadow: none;
    border: none;
    overflow-x: visible !important;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const SpacedDiv = styled.div`
  width: 100%;
  height: 4em;
`;
