import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./scoringscreen.css";
import { Helmet } from "react-helmet";
import ScoringNavigationMobile from "../ScoringNavigation/ScoringNavigationMobile/ScoringNavigationMobile";
import ScoringNavigationDrawer from "../ScoringNavigation/ScoringNavigationDrawer/ScoringNavigationDrawer";
import ScoringForm from "../ScoringForm/ScoringForm";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";

const ScoringScreen = () => {
  const location = useLocation();
  const { state } = location;
  const testId = state?.testId || new URLSearchParams(location.search).get("testId");
  const isEditCompleted = state?.isEditCompleted;
  const shouldShowBackButton = state?.shouldShowBackButton;

  let [currentCriteria, setCurrentCriteria] = React.useState(null);
  let [currentPage, setCurrentPage] = React.useState(0);
  let [isMobile, setIsMobile] = React.useState(
    matchMedia("(max-width: 1200px)").matches
  );
  let [testIncompleteError, setTestIncompleteError] = React.useState("");
  let [applyIncompleteFocus, setApplyIncompleteFocus] = React.useState(false);
  let [submitQuestion, setSubmitQuestion] = React.useState(null);
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.testEndpoint.scopes.read
  });
  let [numQuestions, setNumQuestions] = React.useState();

  useEffect(() => {
    execute("GET", protectedResources.getTestLength.endpoint, protectedResources.getTestLength.scopes).then((response) => {
      if (response) {
        setNumQuestions(Number(response));
      }
    })
  }, [execute]);

  let headingRef = useRef(null);

  let [incompleteQuestions, setIncompleteQuestions] = React.useState();
  
  useEffect(() => {
    if (!numQuestions || numQuestions === 0) {
      return
    }

    if (!incompleteQuestions) {
      execute("GET", protectedResources.getAllResponses.endpoint + testId, protectedResources.getAllResponses.scopes).then((response) => {
        if (response && response.length > 0) {
          const completed = response.map((criteria) => criteria.criteriaid);

          const incompleted = [...Array(numQuestions).keys()].filter(
            (num) => num !== 0 && !completed.includes(num)
          );

          setIncompleteQuestions(incompleted);
        } else if (response.length === 0) {
          const incompleted = [...Array(numQuestions).keys()].map(x => x + 1);
          setIncompleteQuestions(incompleted);
        }
    })}
  }, [execute, numQuestions, currentPage])

  useEffect(() => {
    // handle resizing
    const mediaQuery = matchMedia("(max-width: 1200px)");
    const handleResize = (event) => setIsMobile(event.matches);

    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const formProps = {
    currentPage,
    setCurrentPage,
    incompleteQuestions,
    currentCriteria,
    testId,
    numQuestions,
    testIncompleteError,
    setTestIncompleteError,
    setApplyIncompleteFocus,
    setIncompleteQuestions,
    setCurrentCriteria,
    headingRef,
    submitQuestion,
    setSubmitQuestion,
    isEditCompleted
  };

  const navProps = {
    testId,
    currentCriteria,
    setCurrentPage,
    testIncompleteError,
    applyIncompleteFocus,
    setApplyIncompleteFocus,
    incompleteQuestions,
    headingRef,
    setSubmitQuestion,
  };

  return (
    <>
      <Helmet>
        <title>Scoring Screen </title>
      </Helmet>

      <div className="scoring-body">
        {isMobile ? (
          <ScoringNavigationMobile {...navProps} />
        ) : (
          <ScoringNavigationDrawer {...navProps} />
        )}

        <ScoringForm {...formProps} />
      </div>
    </>
  );
};

export default ScoringScreen;
