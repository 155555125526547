import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrintButton from "../PrintPage";
import "./companyTable.css";
import sortableTables from "./sortable-tables-company";
import "../DashboardTable/sortable-tables.css";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";

export default function CompanyTable(companyName) {
  const navigate = useNavigate();
  const { execute } = useFetchWithMsal({
    scopes: protectedResources.getCompanyDashboard.scopes.read
  });
  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    if(!companyData){
      execute("GET", protectedResources.getCompanyDashboard.endpoint + encodeURIComponent(companyName.company.replaceAll("%20", " ")), protectedResources.getCompanyDashboard.scopes).then((response) => {
        setCompanyData(response);
      })
    }
  }, [execute, companyData])
  let avg = 0;

  if (companyData && companyData.length > 0) {
    avg = (companyData.reduce((total, next) => total + next.testscore, 0)) / companyData.length;
  }

  //linking to test-specific report
  const exportReport = (testid) => {
    navigate("/exportResults", {
      state: { id: testid, showBackButton: true }
    });
  };

  const dateParse = (date) => {
    let parseDate = new Date(date);
    parseDate = new Date(parseDate.getTime() + parseDate.getTimezoneOffset() * 60000)
    const month = parseDate.toLocaleString('default', { month: 'short' });
    const day = parseDate.getDate();
    const year = parseDate.getFullYear();
    return (month + " " + day + ", " + year);
  };

  const filteredCompanyData = companyData && companyData.map((company) => ({
    id: company.id,
    score: company.testscore,
    date: dateParse(company.date),
    testid: company.testid,
    export: <PrintButton name="Print Page" documentName={`${companyName} Accessibility Test Performed on ${dateParse(company.date)}`}/>,
  }));


  useEffect(() => {
    //initializing enable-a11y sortable tables module
    sortableTables.init();
  }, []);

  return (
    <>
      <div>
        <figcaption id="fig-caption">
          <div className="centered-text">
            <p>Accessibility Indicator Data (AID) Scores For Company</p>
          </div>
        </figcaption>
        <div className="table-container">
          <div
            className="deque-table-sortable-group"
            id="company-sortable-table"
          >
            <div id="company-specific-table__sort-instructions">
              <div className="centered-text">
                Click the table heading buttons to sort the table by the data in
                its column.
              </div>
            </div>
            <table
              id="company-specific-table"
              className="deque-table-sortable"
              role="grid"
              data-aria-live-update="The table ${caption} is now ${sortedBy}"
              data-ascending-label="Sorted in ascending order"
              data-descending-label="Sorted in descending order"
              aria-labelledby="fig-caption"
            >
              <thead>
                <tr>
                  <th scope="col">
                    <button
                      className="sortableColumnLabel"
                      aria-describedby="company-specific-table__sort-instructions"
                    >
                      Accessibility Score
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      className="sortableColumnLabel"
                      aria-describedby="company-specific-table__sort-instructions"
                    >
                      Date of Test
                    </button>
                  </th>
                  <th scope="col" className="unsortableColumnLabel">
                    Export Test Report
                  </th>
                </tr>
              </thead>
              <tbody id="company-specific-body">
                { filteredCompanyData && filteredCompanyData.length > 0 && filteredCompanyData.map((test, index) => (
                    <tr key={index}>
                      <td>{test.score}</td>
                      <td>{test.date}</td>
                      <td>
                        <button className="export-button" onClick={() => exportReport(test.testid)}>
                          <img src="/exportIconW.png" alt="Export report"/>
                        </button>
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>
            <div className="centered-text">
              <span
                className="deque-table-sortable__live-region sr-only"
                aria-live="assertive"
                data-read-captions="false"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
