import * as React from 'react';
import './buttonStyle.css'
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export default function BackButton({ returnTo = "accountOverview", testId = null }) {
  let ariaLabel;
  let label;

  switch (returnTo) {
    case "ScoringScreen":
      ariaLabel = "Back to Questionnaire";
      label = "Return to Test";
      break;
    case "ManageTest":
      ariaLabel = "Back to Manage Tests page";
      label = "Manage Tests";
      break;
    case "accountOverview":
    default:
      ariaLabel = "Back to Account Overview";
      label = "Dashboard"
      break;
  }
  
  return (
    <div className='back-button-container'>
      <Link to={`/${returnTo ?? "accountOverview"}${testId ? `?testId=${testId}` : ""}`}>
        <button
          className="back-button"
          type="button"
          autoFocus={true}
          aria-label={ariaLabel}
        >
          <FaArrowLeft />{" "}{label}
        </button>
      </Link>
    </div>
  );
}