/* 
    Temp file to store data.
    To be replace with DB calls.
*/

export function setItem(name, data) {
    window.localStorage.setItem(name, JSON.stringify(data));
}

export function updateItem(name, newData) {
    let data = getItem(name);
    let updatedData = Object.assign(data, newData);
    setItem(name, updatedData);
}

export function getItem(name) {
    let data = window.localStorage.getItem(name);
    return JSON.parse(data);
}

export function deleteItem(name) {
    window.localStorage.removeItem(name);
}