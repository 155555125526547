import { HeaderText } from "./__styled__/Header";

const Header = (props) => {
  return (
    <div>
      <HeaderText>{props.headerTitle.replaceAll("%20", " ")}</HeaderText>
    </div>
  );
};

export default Header;
