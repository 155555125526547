import React from "react";
import { useNavigate } from "react-router-dom";
import "./NewTestScreen.css";
import icon from "../icons/open.svg";
import { Helmet } from "react-helmet";

const NewTestScreen = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>New Test Instructions and Tool Downloads</title>
      </Helmet>
      <div id="new-test-screen-container">
        <h1 id="new-test-screen-title">
          Download and install tools for test
        </h1>
        <p id="new-test-desc">
          The Accessibility Indicator Test involves a series of tests,
          including visual checks, tests requiring the assistance
          of an automated tool, code checks, keyboard testing, and screen reader testing. 
          Each test provides detailed instructions, visual examples, and rationale to 
          help with its completion.
          <br/><br/>
          Below are the tools that you will need to download to complete the Accessibility Indicator Test, 
          if you do not already have them downloaded.
        </p>
        <ul id="test-screen-list">
          <li className="download-list-item">
            <p>
            <a
              id="mainContent"
              className="new-test-screen-links"
              href="https://wave.webaim.org/extension/"
              target="_blank"
            >
              Download & install the WAVE toolbar
              <img
              className="icon"
              src={icon}
              alt="(opens in a new window)."
              />
            </a>
            </p>
          </li>
          <li className="download-list-item">
            <p>
            <a
              className="new-test-screen-links"
              href="https://chrispederick.com/work/web-developer/"
              target="_blank"
            >
              Download the Web Developer toolbar
              <img
              className="icon"
              src={icon}
              alt="(opens in a new window)."
              />
            </a>
            </p>
          </li>
          <li className="download-list-item">
            <p>
            <a
              className="new-test-screen-links"
              href="https://matatk.agrip.org.uk/landmarks/"
              target="_blank"
            >
              Download & install the Landmarks extension
              <img
              className="icon"
              src={icon}
              alt="(opens in a new window)."
              />
            </a>
            </p>
          </li>
          <li className="download-list-item">
            <p>
            On a Windows machine, ensure that a screenreader is installed.
            We recommend{" "}
            <a
              className="new-test-screen-links"
              href="https://www.nvaccess.org/download/"
              target="_blank"
            >
              NVDA by NVAccess
              <img
              className="icon"
              src={icon}
              alt="(opens in a new window)."
              />
            </a>
            </p>
          </li>
          <li className="download-list-item">
            <p>
              On a Mac laptop, a screenreader called VoiceOver is built-in. 
              Please ensure that you are comfortable with VoiceOver before starting the test.
            </p>
          </li>
          <li className="download-list-item">
            <p>
              If you are using automated testing to help scan the website, the results will be available on the individual test result from Pope Tech or Lighthouse.
            </p>
          </li>
        </ul>
        <form id="new-test-screen-form">
          <input
            type="Button"
            value="Next"
            id="new-test-screen-button"
            onClick={() => navigate("/BasicInfo")}
            readOnly
          />
        </form>
      </div>
    </>
  );
};

export default NewTestScreen;
