const ExplanationText = () => {
  return (
    <div>
      <p style={{ textAlign: "left" }}>
        The accessibility indicator data scoring tool is a proprietary tool that employs a selection of automated and manual tests to measure the
        presence of key accessibility features and the lack of key accessibility barriers.
      </p>
    </div>
  );
};

export default ExplanationText;
