import { useEffect, useRef, useState } from "react";
import ScoringNavigation from "../ScoringNavigation.jsx";
import "./scoringNavigationDrawer.css";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

const ScoringNavigationDrawer = (props) => {
  const { applyIncompleteFocus = false } = props;

  const detailsRef = useRef(null);
  const [ isOpen, setIsOpen ] = useState(false);

  // move the form to the right when the drawer is open
  useEffect(() => {
    const elements = document.getElementsByClassName("ssform");
    let form = null;
    if (elements.length > 0) {
      form = elements[0];
      if (isOpen) {
        form.style.marginLeft = "300px";
      } else {
        form.style.margin = "auto";
      }
    }
    return () => { if (form) form.style.marginLeft = "auto" };
  }, [isOpen])

  // open drawer when incomplete error occurs on questionnaire submission
  useEffect(() => {
    if (applyIncompleteFocus && !isOpen) {
      setIsOpen(true);
    }
  }, [applyIncompleteFocus]);

  // sets the drawer's open state on toggle so we can trigger the scroll animation
  useEffect(() => {
    const currentRef = detailsRef.current;
    const toggleFunction = () => setIsOpen(detailsRef.current.open);
    currentRef.addEventListener("toggle", toggleFunction);
    return () => currentRef.removeEventListener("toggle", toggleFunction);
  }, []);

  // handle the changing size of the nav bar on scroll by adjusting the drawer padding
  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop < 1) {
        detailsRef.current.style.paddingTop = "5rem";
      } else {
        detailsRef.current.style.paddingTop = "3rem";
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  return (
    <div className="scoring-nav-drawer">
        <details className="scoring-nav-details" ref={detailsRef} open={isOpen}>
          <summary tabIndex={0}>
            <div className="summary-icon">
              <ChevronLeft className="open-icon" title="Hide question navigation"/>
              <ChevronRight className="close-icon" title="Display question navigation"/>
            </div>
          </summary>
          <ScoringNavigation drawerIsOpen={isOpen} { ...props } />
        </details>
    </div>
  );
};

export default ScoringNavigationDrawer;