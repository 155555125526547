import { HeaderContainer } from "./__styled__/EditQuestionnaire"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { Link } from "react-router-dom"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { useRef } from "react"

let dialogIndex = 0

export const Header = ({
  onClickSave,
  isOnTop,
  onDelete
}) => {
  const dialogRef = useRef();

  return (
    <HeaderContainer isOnTop={isOnTop}>
      {isOnTop && (
        <Link
          to="/questions"
          aria-label="Return to the questions summary"
          title="Return to questions summary"
          className="btn summary"
        >
          <ArrowBackIosIcon className="icon"/>Summary
        </Link>
      )}

      <div>
        <button
          aria-label="Save the criteria data"
          title="Save the criteria data"
          className="save"
          onClick={onClickSave}
        >
          Save
        </button>

        <>
          <dialog
            ref={dialogRef}
            id="delDialog"
            aria-modal="true"
            aria-labelledby="Displaying the delete question modal"
            aria-describedby={`delete_label--${dialogIndex}`}
          >
            <script>dialogIndex++;</script>
            <span className="sr-only">Now displaying delete modal.</span>
            <button
              className="x"
              title="close this box"
              onClick={() => dialogRef.current.close()}
            >
              <CloseRoundedIcon />
            </button>
            <div role="document">
              <h2 className="modal_title" id={`delete_label--${dialogIndex}`}>
                Are you sure you would like to delete this criteria?
              </h2>
              <p className="modal_description" id={`delete_desc--${dialogIndex}`}>This action cannot be undone.</p>
              <div className="modal_options">
                <div className="modal_btn_container">
                  <button
                    type="reset"
                    className="modal_button modal_cancel" 
                    onClick={() => dialogRef.current.close()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="modal_btn_container">
                  <button
                    type="submit"
                    className="modal_button modal_delete" 
                    onClick={() => {
                      onDelete();
                      dialogRef.current.close();
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </dialog>
        </>

        <button
          onClick={() => dialogRef.current.showModal()}
          id="dele"
          aria-label="Delete the criteria"
          title="Delete the criteria"
        >
          Delete
        </button>
      </div>
    </HeaderContainer>
    )
  }
