import ScoringNavigation from "../ScoringNavigation.jsx";
import { useState, useEffect, useRef } from "react";
import dialogPolyfill from 'dialog-polyfill';
import "./scoringNavigationMobile.css";
import { XLg } from 'react-bootstrap-icons';

const ScoringNavigationMobile = (props) => {
    const {
        headingRef,
        applyIncompleteFocus = false
    } = props;

    const dialogRef = useRef();
    const [modalOpen, setModalOpen] = useState(false);

    // open modal when incomplete error occurs on questionnaire submission
    useEffect(() => {
        if (applyIncompleteFocus && !modalOpen) {
            toggleModal();
        }
    }, [applyIncompleteFocus]);

    const toggleModal = () => {
        if (modalOpen) {
            dialogRef.current.close();
            setModalOpen(false);
            // apply focus on the question header
            if (headingRef && headingRef.current){
                headingRef.current.focus();
            }
        } else {
            dialogRef.current.showModal();
            setModalOpen(true);
        }
    }

    useEffect(() => {
        // If we are using the polyfill, then initialize it
        if (window.dialogPolyfill) {
          dialogPolyfill.registerDialog(dialogRef.current);
        }
    }, [])

    return (
        <div className="mobile-scoring-nav">
            { !modalOpen && <div className="btn-cont"><button onClick={() => toggleModal()}>Select Another Question</button></div> }
            <dialog ref={dialogRef}>
                <div className="dialog-header">
                    <h2>Question Navigation</h2>
                    <div className="close-cont">
                        <button className="close-nav" aria-label="Close question navigation" onClick={() => toggleModal()}>
                            <XLg/>
                        </button>
                    </div>
                </div>
                <ScoringNavigation 
                    {...props} 
                    modalOpen={modalOpen} 
                    setModalOpen={setModalOpen} 
                    dialogRef={dialogRef} 
                    toggleModal={toggleModal}
                />
            </dialog>
        </div>
    )
};

export default ScoringNavigationMobile;