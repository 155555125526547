import "./manageTestsTable.css";
import React, { useEffect, useRef, useState } from "react";
import sortableTables from "./sortable-tables";
import "../DashboardTable/sortable-tables.css";
import { Icon } from "@iconify/react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { appRoles, protectedResources } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { getItem, deleteItem } from "../../utilities/storage";
import { deleteWebsite } from "../../utilities/api";

export const DeleteButton = ({
  companies,
  setCompanies,
  testId,
  label,
  dialogIndex,
}) => {
  const dialogRef = useRef(null);

  const { execute } = useFetchWithMsal({
    scopes: [...protectedResources.testEndpoint.scopes.read],
  });

  const onDeleteTest = async () => {
    try {
      execute("DELETE", protectedResources.deleteTest.endpoint + testId, protectedResources.deleteTest.scopes);
      dialogRef.current.close();
      let newCompanies = companies.filter(
        (company) => company.testid !== testId
      );
      deleteWebsite(testId).then(() => {
        execute("DELETE", protectedResources.deleteLighthouse.endpoint + `/${testId}`, protectedResources.deleteCriteria.scopes);
        deleteItem(testId);
      });
      
      setCompanies(newCompanies);
    } catch (err) {
      console.error("Delete test button", JSON.stringify(err));
    }
  };

  return (
    <>
      <dialog
        ref={dialogRef}
        id="delDialog"
        aria-modal="true"
        aria-labelledby={`delete_label--${dialogIndex}`}
      >
        <span className="sr-only">Now displaying delete modal.</span>
        <button
          className="x"
          onClick={() => dialogRef.current.close()}
          aria-label="Close this modal"
        >
          <CloseRoundedIcon />
        </button>
        <div role="document">
          <h2
            className="modal_title"
            id={`delete_label--${dialogIndex}`}
            style={{ lineHeight: "2rem" }}
          >
            Are you sure you would like to delete this test?
          </h2>
          <p className="modal_description" id={`delete_desc--${dialogIndex}`}>
            This action cannot be undone.
          </p>
          <div className="modal_options">
            <div className="modal_btn_container">
              <button
                type="reset"
                className="modal_button modal_cancel"
                onClick={() => dialogRef.current.close()}
              >
                Cancel
              </button>
            </div>
            <div className="modal_btn_container">
              <button
                type="submit"
                className="modal_button modal_delete"
                onClick={() => onDeleteTest()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </dialog>

      <button
        onClick={() => dialogRef.current.showModal()}
        id="dele"
        className="delete-btn"
        aria-label={label}
      >
        <Icon icon="gg:trash-empty" inline={true} width={15} height={15} />
      </button>
    </>
  );
};

export default function ManageTestsTable() {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const userID = account.localAccountId;
  const { execute } = useFetchWithMsal({
    scopes: [...protectedResources.getTests.scopes.read],
  });

  const [companies, setCompanies] = useState();
  const [allCompanies, setAllCompanies] = useState();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    execute("GET", protectedResources.getTests.endpoint + `${userID}`, protectedResources.getTests.scopes).then(
      (response) => {
        setCompanies(response);
      }
    );
  }, [execute]);

  useEffect(() => {
    if (account?.idTokenClaims['roles']?.includes(appRoles.superAdmin)) {
      setIsSuperAdmin(true);
      execute("GET", protectedResources.getTests.endpoint, protectedResources.getTests.scopes).then(
        (response) => {
          setAllCompanies(response); 
        }
      );
    } else {
      setIsSuperAdmin(false);
    }
  }, [execute]);

  const getLink = (company) => {
    return company.iscompleted
      ? `/Results?id=${company.testid}&showBackButton=false`
      : `/ScoringScreen?testId=${company.testid}`;
  };

  const dateParse = (date) => {
    const parseDate = new Date(date);
    const month = parseDate.toLocaleString("default", { month: "short" });
    const day = parseDate.getDate() + 1;
    const year = parseDate.getFullYear();
    return month + ". " + day + ", " + year;
  };

  React.useEffect(() => {
    sortableTables.init();
  }, [companies]);

  return (
    <div className="table-section">
      <figcaption id="fig-caption">
        <div className="centered-text">
          <h1 id="manage-my-tests-title">My Saved Tests</h1>
          <p>Manage My Unfinished Tests</p>
        </div>
      </figcaption>
      <div className="table-container" style={{ marginTop: "1em" }}>
        <div
          className="deque-table-sortable-group"
          id="overview-sortable-table"
        >
          <div id="account-overview-table__sort-instructions">
            <div className="centered-text">
              Click the table heading buttons to sort the table by the data in
              its column.
            </div>
          </div>
          <table
            id="account-overview-table"
            className="deque-table-sortable"
            role="grid"
            data-aria-live-update="The table ${caption} is now ${sortedBy}"
            data-ascending-label="Sorted in ascending order"
            data-descending-label="Sorted in descending order"
            aria-labelledby="fig-caption"
          >
            <thead>
              <tr>
                <th scope="col">
                  <button
                    className="sortableColumnLabel"
                    aria-describedby="account-overview-table__sort-instructions"
                  >
                    Project Name
                  </button>
                </th>
                <th scope="col">
                  <button
                    className="sortableColumnLabel"
                    aria-describedby="account-overview-table__sort-instructions"
                  >
                    Account Name
                  </button>
                </th>
                <th scope="col">
                  <button
                    className="sortableColumnLabel"
                    aria-describedby="account-overview-table__sort-instructions"
                  >
                    Completed
                  </button>
                </th>
                <th scope="col">
                  <button
                    className="sortableColumnLabel"
                    aria-describedby="account-overview-table__sort-instructions"
                  >
                    Date
                  </button>
                </th>
                <th scope="col" className="unsortableColumnLabel">
                  Automated Scan
                </th>
                <th scope="col" className="unsortableColumnLabel">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody id="account-overview-body">
              {companies &&
                companies.length > 0 &&
                companies.map((company, idx) => (
                  <tr key={idx}>
                    <th className="accNameLabel" scope="row">
                      <a
                        href={getLink(company)}
                        className="table-link"
                        aria-label={
                          company.iscompleted
                            ? `Test results for ${company.projectname}`
                            : `Continue unfinished test for ${company.projectname}`
                        }
                      >
                        {company.projectname}
                      </a>
                    </th>
                    <td>
                      <a
                        href={`/company/${
                          company.companyname
                        }?from=${window.location.pathname.substring(1)}`}
                        className="table-link"
                        aria-label={`Account page for ${company.companyname}`}
                      >
                        {company.companyname}
                      </a>
                    </td>
                    <td>{`${company.iscompleted}`}</td>
                    <td>{dateParse(company.date.substring(0, 10))}</td>
                    <td>{ getItem(company.testid)?.scan.toString() ?? 'false'}</td>
                    <td>
                      <DeleteButton
                        companies={companies}
                        setCompanies={setCompanies}
                        testId={company.testid}
                        label={`Delete this test for project ${company.projectName} of the ${company.companyname} account`}
                        dialogIndex={idx}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="centered-text">
            <span
              className="deque-table-sortable__live-region sr-only"
              aria-live="assertive"
              data-read-captions="false"
            ></span>
          </div>
        </div>
      </div>
      { isSuperAdmin && (
        <>
        <figcaption id="fig-caption">
          <div className="centered-text">
            <h1 id="manage-all-tests-title">All Users Saved Tests</h1>
            <p>Manage All User Tests</p>
          </div>
        </figcaption>
        <div className="table-container" style={{ marginTop: "1em" }}>
          <div
            className="deque-table-sortable-group"
            id="overview-sortable-table"
          >
            <div id="account-overview-table__sort-instructions">
              <div className="centered-text">
                Click the table heading buttons to sort the table by the data in
                its column.
              </div>
            </div>
            <table
              id="account-overview-table"
              className="deque-table-sortable"
              role="grid"
              data-aria-live-update="The table ${caption} is now ${sortedBy}"
              data-ascending-label="Sorted in ascending order"
              data-descending-label="Sorted in descending order"
              aria-labelledby="fig-caption"
            >
              <thead>
                <tr>
                  <th scope="col">
                    <button
                      className="sortableColumnLabel"
                      aria-describedby="account-overview-table__sort-instructions"
                    >
                      Project Name
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      className="sortableColumnLabel"
                      aria-describedby="account-overview-table__sort-instructions"
                    >
                      Account Name
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      className="sortableColumnLabel"
                      aria-describedby="account-overview-table__sort-instructions"
                    >
                      User Name
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      className="sortableColumnLabel"
                      aria-describedby="account-overview-table__sort-instructions"
                    >
                      Completed
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      className="sortableColumnLabel"
                      aria-describedby="account-overview-table__sort-instructions"
                    >
                      Date
                    </button>
                  </th>
                  <th scope="col" className="unsortableColumnLabel">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody id="account-overview-body">
                {allCompanies &&
                  allCompanies.length > 0 &&
                  allCompanies.map((allCompany, id) => (
                    <tr key={id}>
                      <th className="accNameLabel" scope="row">
                        <a
                          href={getLink(allCompany)}
                          className="table-link"
                          aria-label={
                            allCompany.iscompleted
                              ? `Test results for ${allCompany.projectname}`
                              : `Continue unfinished test for ${allCompany.projectname}`
                          }
                        >
                          {allCompany.projectname}
                        </a>
                      </th>
                      <td>
                        <a
                          href={`/company/${
                            allCompany.companyname
                          }?from=${window.location.pathname.substring(1)}`}
                          className="table-link"
                          aria-label={`Account page for ${allCompany.companyname}`}
                        >
                          {allCompany.companyname}
                        </a>
                      </td>
                      <td>{`${allCompany.username}`}</td>
                      <td>{`${allCompany.iscompleted}`}</td>
                      <td>{dateParse(allCompany.date.substring(0, 10))}</td>
                      <td>
                        <DeleteButton
                          allCompanies={allCompanies}
                          setAllCompanies={setAllCompanies}
                          testId={allCompany.testid}
                          label={`Delete this test for project ${allCompany.projectName} of the ${allCompany.companyname} account`}
                          dialogIndex={id}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="centered-text">
              <span
                className="deque-table-sortable__live-region sr-only"
                aria-live="assertive"
                data-read-captions="false"
              ></span>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
}
