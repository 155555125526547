import React from "react";
import "./navbar.css";

function Icon() {
  return (
    <div className="logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        version="1.1"
        viewBox="0 0 57.6 162"
        xmlSpace="preserve"
      >
        <title></title>
        <g transform="matrix(1.33333 0 0 -1.33333 0 162)">
          <g
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            transform="scale(.1)"
          >
            <path
              fill="#000"
              d="M228.109 845.051c-84.14.851-152.59 73.078-152.59 161.019l-.011 6.59c3.289 85.64 70.328 152.73 152.601 152.73 85.067 0 154.274-71.47 154.274-159.32 0-87.961-69.192-160.179-154.274-161.019zm0 369.949c-58.539 0-114.726-26.78-152.59-72.19l-.011 72.19H25.906V641.227H75.52v227.945c37.664-45.848 93.835-72.879 152.589-72.879 112.426 0 203.895 94.105 203.895 209.777 0 115.2-91.469 208.93-203.895 208.93"
            ></path>
            <path
              fill="#fff"
              d="M176.938.23c46.222-2 86.839 9.008 123.738 33.73 37.918 26.212 58.855 59.759 60.476 96.981 1.719 39.637-17.761 79.071-50.91 102.938l-.086.074c-.156.11-15.039 11.633-30.82 18.266-14.285 6.011-35.957 13.886-88.152 26.66-2.793.684-5.539 1.309-8.262 1.934-3.727.859-7.391 1.687-10.914 2.605-32.985 8.223-59.121 19.434-77.692 33.281-19.101 16.492-26.156 30.149-25.332 48.836.852 19.543 12.414 38.43 32.586 53.156 22.653 16.739 55.461 25.079 92.645 23.465 42.133-1.836 73.961-22.715 108.265-47.761l.938-.696 37.781 35.141c-3.23 2.879-6.062 4.89-17.222 12.598-35.063 23.73-74.836 46.042-127.707 48.347-47 2.012-89.184-7.578-121.57-27.871-35.954-24.324-56.888-58.613-57.313-94.004-.313-24.238 3.37-50.488 32.183-78.242 27.496-26.484 71.461-45.723 130.629-57.18 41.289-7.984 79.047-23.047 98.531-39.297 23.528-21.55 31.829-37.191 31.829-59.902-.957-21.91-15.176-42.7-39.016-57.187-23.445-17.532-57.109-26.813-92.461-25.274-46.019 2.012-93.578 22-141.363 59.422l-.961.781L0 77.351l1.25-1.07C55.64 29.328 114.742 3.738 176.938.231"
            ></path>
          </g>
        </g>
      </svg>{" "}
    </div>
  );
}

export default Icon;
