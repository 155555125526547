export function sortCompanies(sortValue, filteredCompanies) {
    let obj = JSON.parse(JSON.stringify(filteredCompanies));
    
    if (sortValue == 7 || sortValue == 5) {
        obj.sort(function (a, b) {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });
    } else if (sortValue == 6) {
        obj.sort(function (a, b) {
            if (a.y < b.y) return 1;
            if (a.y > b.y) return -1;
            return 0;
        });
    } else if (sortValue == 8) {
        obj.sort(function (a, b) {
            if (new Date(a.date) < new Date(b.date)) return -1;
            if (new Date(a.date) > new Date(b.date)) return 1;
            return 0;
        });
    } else if (sortValue == 9) {
        obj.sort(function (a, b) {
            if (new Date(a.date) < new Date(b.date)) return 1;
            if (new Date(a.date) > new Date(b.date)) return -1;
            return 0;
        });
    }
    return obj;
}

export function filterCompanies(filterValue, filteredCompanies, selectedYear) {
    if (selectedYear === null) {
        //will default to current year is none is selected
        const d = new Date();
        selectedYear = d.getFullYear();
    }

    let year = parseInt(selectedYear);

    if (filterValue === 0) {
        return filteredCompanies;
    }

    return filteredCompanies.filter((company) =>
        filterByDate(company.date, filterValue, year)
    );
}

function filterByDate(date, filterValue, year) {
    const Dates = new Date(date);
    var quarter;
    
    if (Dates >= new Date(year, 0, 1) && Dates <= new Date(year, 2, 31)) {
        quarter = 1;
    } else if (Dates > new Date(year, 3, 1) && Dates < new Date(year, 5, 30)) {
        quarter = 2;
    } else if (Dates > new Date(year, 6, 1) && Dates < new Date(year, 8, 30)) {
        quarter = 3;
    } else if (Dates > new Date(year, 9, 1) && Dates < new Date(year, 11, 31)) {
        quarter = 4;
    } else {
        quarter = -1;
    }

    if (filterValue == 0) {
        return true;
    } else if (quarter == filterValue) {
        return true;
    } else {
        return false;
    }
}

export function filterByIndustry(industryid, filteredCompanies) {
    if (industryid === 0) {
        return filteredCompanies;
    }
    return filteredCompanies.filter(company => company.industryid === industryid);
}

export function filterByYear(year, filteredCompanies) {
    if (year === "No filter") {
        return filteredCompanies;
    }
    return filteredCompanies.filter(company => company.year === year);
}