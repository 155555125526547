import React, { useState } from "react";
import {
  FieldContainer,
  CategoryContainer,
} from "./__styled__/EditQuestionnaire";
import { ImageDropbox } from "./ImageDropbox";
import { Header } from "./Header";
import AnswerTable from "../AnswersTable";
import {
  editCriteria,
  updateReasonings,
  updateGuidance,
  createGuidance,
  createReasoning,
  updateImage,
  addImage,
  deleteCriteria,
} from "../../databaseFunctions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import icon from "../icons/open.svg";
import { protectedResources } from "../../authConfig";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";

export const PureEditQuestionnaire = ({
  criteriaNum,
  criteriaSize,
  criteriaFields,
  criteria,
  criteriaType,
  isNewQuestion,
  image,
  isSaving,
  isUpload,
  answerProp,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { execute } = useFetchWithMsal({
    scopes: [
      ...protectedResources.testEndpoint.scopes.read,
      ...protectedResources.deleteCriteria.scopes.write,
    ],
  });

  const navigate = useNavigate();
  let [shouldShowErrors, setShouldShowErrors] = useState(false);
  let initialFieldErrors = {};
  criteriaFields.forEach((field) => {
    initialFieldErrors[field.name] = false;
  });
  let [fieldErrors, setFieldErrors] = useState(initialFieldErrors);
  let [imageError, setImageError] = useState(false);
  let [criteriaId, setCriteriaId] = useState();

  const fieldChange = (name, value) => {
    if (value.length > 0) {
      fieldErrors[name] = false;
      setFieldErrors({ ...fieldErrors });
    } else {
      fieldErrors[name] = true;
      setFieldErrors({ ...fieldErrors });
    }
    switch (name) {
      case "Criteria":
        criteria.criteria = value;
        return;
      case "Rationale":
        criteria.rationale = value;
        return;
      case "Alternate Image Text":
        image.alttext = value;
        return;
      case "Description":
        criteria.testdescription = value;
        return;
      case "Guidance Intro":
        criteria.feedbackintro = value;
        return;
      case "Test Type":
        criteria.criteriatypeid = getCriteriaIdByName(value);
        return;
      case "Capability":
        criteria.capability = value;
        return;
      default:
        return;
    }
  };

  const getValue = (name) => {
    switch (name) {
      case "Criteria":
        return criteria.criteria;
      case "Rationale":
        return criteria.rationale;
      case "Alternate Image Text":
        return image.alttext;
      case "Description":
        return criteria.testdescription;
      case "Guidance Intro":
        return criteria.feedbackintro;
      case "Test Type":
        return criteria.criteriatypeid;
      case "Capability":
        return criteria.capability;
      default:
        return "";
    }
  };

  const onImageChange = (imageFieldName, file) => {
    if (file === null) {
      image.image = null;
      setImageError(true);
    } else {
      image.image = file;
      setImageError(false);
    }
  };

  const save = () => {
    setShouldShowErrors(false);
    let hasErrors = false;
    let error = fieldErrors;
    criteriaFields.forEach((field) => {
      if (field.name === "Visual Example") {
        return;
      }

      if (getValue(field.name) === "" || getValue(field.name) === undefined) {
        error[field.name] = true;
        setShouldShowErrors(true);
        hasErrors = true;
      } else {
        error[field.name] = false;
      }
    });

    answerProp.forEach((answer) => {
      if (
        answer.reasoning === "" ||
        (answer.feedback === "" && answer.score !== -1)
      ) {
        setShouldShowErrors(true);
        hasErrors = true;
        return;
      }
    });

    if (Object.keys(image).length === 0 || image.image === null) {
      setImageError(true);
      setShouldShowErrors(true);
      hasErrors = true;
    } else {
      setImageError(false);
    }

    setFieldErrors(error);

    if (hasErrors) {
      return;
    }

    if (isNewQuestion) {
        const alwaysapplies = answerProp.filter(answer => answer.score === -1 && answer.reasoning != null && answer.reasoning != "").length > 0;
        
        const body = {
          criteriatypeid: criteria.criteriatypeid,
          capabilityid: criteria.capabilityid,
          criteria: criteria.criteria,
          rationale: criteria.rationale,
          testdescription: criteria.testdescription,
          feedbackintro: criteria.feedbackintro,
          alwaysapplies,
        };

        execute("POST", protectedResources.createCriteria.endpoint, protectedResources.createCriteria.scopes, body).then(
          (response) => {
            if (response && response.length > 0) {
              setCriteriaId(response[0].criteriaid);
              createGuidance(answerProp, response[0].criteriaid, execute);
              createReasoning(answerProp, response[0].criteriaid, execute);
              addImage(image, response[0].criteriaid, execute);
              setShouldShowErrors(false);
              navigate("/questions");
            }
          }
        );
    } else {
        editCriteria({
          criteria,
          execute
        });
        updateReasonings(answerProp, execute);
        updateGuidance(answerProp, execute);

        updateImage(image, criteria.criteriaid, execute);
        setShouldShowErrors(false);
        navigate("/questions");
    }
  };

  const onDelete = () => {
    deleteCriteria(criteria.criteriaid, execute);
    navigate("/questions");
  };

  const getInputValue = (fieldName) => {
    switch (fieldName) {
      case "Criteria":
        return criteria.criteria;
      case "Rationale":
        return criteria.rationale;
      case "Alternate Image Text":
        return image.alttext;
      case "Description":
        return criteria.testdescription;
      case "Guidance Intro":
        return criteria.feedbackintro;
      case "Test Type":
        return getCriteriaTypeNameFromId(criteria.criteriatypeid);
      case "Capability":
        return criteria.capability;
      default:
        return "";
    }
  };

  // we may want to consider changing the next 2 funcs to get values from the backend at a later date, but 
  // since there is currently no way to add a new test type or changing an existing on this will not change 
  // so I didn't want to add calls that are unnecessary
  const getCriteriaTypeNameFromId = (id) => {
    switch (id) {
      case 1:
        return "Visual Check";
      case 2:
        return "Visual Check + Tool";
      case 3:
        return "Automated Tool";
      case 4:
        return "Code Check";
      case 5:
        return "Keyboard";
      case 6:
        return "Keyboard & Screen Reader";
      default:
        return "";
    }
  }

  const getCriteriaIdByName = (name) => {
    switch (name) {
      case "Visual Check":
        return 1;
      case "Visual Check + Tool":
        return 4;
      case "Automated Tool":
        return 3;
      case "Code Check":
        return 4;
      case "Keyboard":
        return 5;
      case "Keyboard & Screen Reader":
        return 6;
      default:
        return "";
    }
  }

  return (
    <>
      <Header
        tabIndex="-1"
        criteriaSize={criteriaSize}
        criteriaNum={criteriaNum}
        isAddCriteria={!!criteriaNum}
        onClickSave={save}
        isSaving={isSaving}
        isOnTop={true}
        onDelete={onDelete}
      />
      <CategoryContainer>
        {!!criteriaNum ? (
          <h1>Criteria #{criteriaNum}</h1>
        ) : (
          <h1>Create New Criteria</h1>
        )}
        <p>
          Fields with <span className="required">*</span> are required
        </p>
        <p>
          Fields with <span className="highlight">(MD)</span> accept markdown
          formatted text.&nbsp;
          <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">
            Learn more about markdown.
            <img className="icon" src={icon} alt="(opens in a new window)." />
          </a>
          .
        </p>

        {criteriaFields &&
          criteriaFields.map((field) => (
            <FieldContainer key={field.name}>
              <label
                htmlFor={field.name.replaceAll(" ", "-")}
                className="fields"
              >
                {field.name}{" "}
                {(field.name === "Description" ||
                  field.name === "Rationale" ||
                  field.name === "Guidance Intro") && (
                  <span className="highlight">(MD)</span>
                )}
              </label>

              {field.type === "input" ? (
                <>
                  <textarea
                    id={field.name.replaceAll(" ", "-")}
                    className="textarea"
                    defaultValue={getInputValue(field.name)}
                    required={true}
                    placeholder={"Enter the " + field.name}
                    rows={
                      field.name === "Description" ||
                      field.name === "Rationale" ||
                      field.name === "Guidance Intro"
                        ? 5
                        : 0
                    }
                    onChange={(event) =>
                      fieldChange(field.name, event.target.value)
                    }
                  />
                  {shouldShowErrors && fieldErrors[field.name] && (
                    <div
                      id={"selectError"}
                      style={{ color: "red", paddingTop: 10 }}
                    >
                      {`Please enter the value for ${field.name}`}
                    </div>
                  )}
                </>
              ) : field.type === "dropdown" ? (
                <>
                  <div id={field.name.replaceAll(" ", "-") + "-desc"}>
                    Select the {" " + field.name}
                  </div>
                  <select
                    id={field.name.replaceAll(" ", "-")}
                    aria-describedby={field.name.replaceAll(" ", "-") + "-desc"}
                    onChange={(event) =>
                      fieldChange(field.name, event.target.value)
                    }
                  >
                    <option selected disabled>
                      Select
                    </option>
                    {field.fields.map((f) => (
                      <option key={f} value={f} selected={getInputValue(field.name) === f}>
                        {f}
                      </option>
                    ))}
                  </select>
                  {shouldShowErrors && fieldErrors[field.name] && (
                    <div id={"selectError"} style={{ color: "red" }}>
                      Please make a selection
                    </div>
                  )}
                </>
              ) : (
                <>
                  {imageError && (
                    <div id={"error"} style={{ color: "red" }}>
                      {`Please upload an image`}
                    </div>
                  )}
                  <ImageDropbox
                    id={field.name.replaceAll(" ", "-")}
                    image={image.image}
                    isUpload={isUpload}
                    imageFieldName={field.name}
                    onImageChange={onImageChange}
                    criteriaNum={criteriaNum}
                  />
                </>
              )}
            </FieldContainer>
          ))}
      </CategoryContainer>

      <CategoryContainer>
        <h1>
          Answers <span className="highlight">(MD)</span>{" "}
        </h1>
        <AnswerTable
          answersProp={answerProp}
          shouldShowErrors={shouldShowErrors}
        />
      </CategoryContainer>
      <Header
        onClickSave={save}
        isSaving={isSaving}
        isOnTop={false}
        isAddCriteria={!!criteriaNum}
        criteriaSize={criteriaSize}
        criteriaNum={criteriaNum}
        onDelete={onDelete}
      />
    </>
  );
};
