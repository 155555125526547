import { useRef, useState, useEffect } from 'react'
import { getPagesByError } from "./api";
import { getItem } from "./storage";

export const ResultsTable = (props) => {
    let { data, completed } = props;
    let dialogRef = useRef(null);
    let [ showModal, setShowModal] = useState(false);
    let [ pages, setPages ] = useState([]);
    let [ title, setTitle ] = useState('');

    useEffect(() => {
        if(!showModal) setShowModal(true);
    }, [showModal])

    function handleDetailClick(slug, title) {
        const testId = new URLSearchParams(document.location.search).get("testId");
        getPagesByError(getItem(testId).scan_id, slug).then(resp => {
            setTitle(title);
            setPages(resp.data);
            setShowModal(true);
            dialogRef.current.showModal();
        });
    }
    return (
        <>
            <section className="table-section">
                <h2>Automated Scan Results for Validation</h2>
                <div className="pages table-container">
                    <table className="dark">
                        <caption className="table-title sr-only">Automated Scan Results</caption>
                        <thead className="table-header">
                            <tr>
                                <th scope="col">Issue</th>
                                <th scope="col">Count</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                        { data?.map((item, index) => {
                            return <tr key={`row${index}`}><th scope="row">{item.name}</th><td>{item.count}</td><td className="cell-details">{item.slug && !completed ? <button className="btn-inline" aria-label={`Details for ${item.name}`} onClick={(e) => { e.preventDefault(); handleDetailClick(item.slug, item.name) }}>Details</button> : ''}</td></tr>;
                        })}

                        </tbody>
                    </table>
                </div>
            </section>
            { showModal && (<DetailsModal modalRef={dialogRef} pages={pages} title={title}></DetailsModal>) }
        </>
    )
}

const DetailsModal = (props) => {
    let { modalRef, pages, title } = props;

    function handleButtonClick(e) {
        e.preventDefault();
        modalRef.current.close();
    }
    return (
        <>
            <dialog className="detail-modal" ref={modalRef}>
                <button className="x" onClick={handleButtonClick} aria-label="close"><svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseRoundedIcon"><path d="M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg></button>
                <h1 className="scan-title">{title} pages</h1>
                <div>
                    <ul>
                        {pages.map((page, index) => {
                            let waveURL = `${page.page.full_url}`;
                            return <li key={index}><a href={waveURL} target="_blank">{page.page.full_url} <img src="/static/media/open.ffea2ba22f760f705bd21dbf56dda366.svg" alt="opens in a new window"></img></a></li>
                        })}
                    </ul>
                </div>
            </dialog>
        </>
    )
}