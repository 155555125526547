import styled from "styled-components";

export const HeaderText = styled.h1`
  text-overflow: ellipsis;
  overflow: hidden;

  @media print {
    text-align: center;
    font-size: 3rem;
  }
`;
