import React from "react";
import ManageTestsTable from "../ManageTestsTable";
import "./manageTestsScreen.css";

const ManageTestsScreen = () => {
  return (
    <>
      <div id="ManageTestsScreen-main-container">
        <div id="content-container">
          <div id="content">
            <ManageTestsTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageTestsScreen;
