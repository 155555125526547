import React, { useState, useEffect } from "react";
import minimize from "./minimize-navbar.js";
import { useLocation, useNavigate } from "react-router-dom";
import "./navbar.css";
import Modal from "./UserFrontEndModal/userFrontEndModal";
import NavLogo from "./logo.jsx";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { appRoles, authRequest, loginRequest } from '../../authConfig';
import { InteractionType } from '@azure/msal-browser';

const adminRoles = [appRoles.admin, appRoles.superAdmin];

const Navbar = () => {
    const [menuExpanded, setMenuExpanded] = useState(matchMedia('(min-width: 1330px)').matches);
    const location = useLocation();
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const currentAccount = instance.getActiveAccount();
    
    const authRequest = {
        ...loginRequest,
    };

    const onLoad = async () => {
        if (currentAccount && currentAccount.idTokenClaims['roles']) {

            let intersection = adminRoles.filter((role) => currentAccount.idTokenClaims['roles'].includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    };

    useEffect(() => {
        onLoad();
    }, [instance, currentAccount]);

    useEffect(() => {
        minimize(menuExpanded);
    }, [menuExpanded]);

    useEffect(() => {
        const mediaQuery = matchMedia('(min-width: 1330px)');

        const handleResize = (event) => {
            setMenuExpanded(event.matches);
        };

        mediaQuery.addEventListener('change', handleResize);
        return () => mediaQuery.removeEventListener('change', handleResize);
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 1330) {
            setMenuExpanded(false);
        }
    }, [location]);

    const handleJumpToMain = (e) => {
        e.preventDefault();
        const sectionElement = document.getElementById("mainContent");
        if (sectionElement) {
            sectionElement.scrollIntoView();
            sectionElement.focus();
        }
    }

    const onClickLogout = async (e) => {
        e.preventDefault();
        if (inProgress === InteractionStatus.None) {
            await instance.logoutRedirect();
        }
    }

    return location.pathname === "/" ? (
        <nav id="navbar" aria-label="Primary">
            <a href="/home" id="nav-title">
                <div id="navlogo"><NavLogo /></div>
                <div id="sitetitle">Accessibility Indicators Test</div>
            </a>
        </nav>
    ) : (
        <>
            <nav id="navbar" aria-label="Primary">
                <a onClick={handleJumpToMain} href={location.pathname + "#mainContent"} id="skipLink">SKIP TO MAIN CONTENT</a>
                <a href="/home" id="nav-title">
                    <div id="navlogo"><NavLogo /></div>
                    <div id="sitetitle">Accessibility Indicators Test</div>
                </a>
                <div id="navbarcollapse">
                    <button
                        aria-label="Main navigation"
                        aria-expanded={menuExpanded}
                        aria-controls="navbar-right"
                        id="drawerbtnup"
                        onClick={() => setMenuExpanded(!menuExpanded)}
                    >
                        &#65086;
                    </button>
                </div>
                <div id="navbar-right" role="group" aria-label="Expanded navigation menu">
                    {/*these MUST be href in order to work with the navbar & tabbing properly!!!*/}
                    <a href="/NewTest">New Test</a>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.None}
                        authenticationRequest={authRequest}
                    >
                        {isAuthorized &&
                            <a href="/basicInfo/uploadTest">Upload Test</a>
                        }
                    </MsalAuthenticationTemplate>
                    <a href="/ManageTest">Manage Tests</a>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.None}
                        authenticationRequest={authRequest}
                    >
                        {isAuthorized &&
                            <a href="/accountOverview">Dashboard</a>
                        }
                    </MsalAuthenticationTemplate>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.None}
                        authenticationRequest={authRequest}
                    >
                        {isAuthorized &&
                            <a href="/questions">Edit Survey</a>
                        }
                    </MsalAuthenticationTemplate>
                    <a href="/" className="logout" onClick={(e) => onClickLogout(e)}>Logout</a>
                </div>
            </nav>
            <Modal />
        </>
    );
}

export default Navbar;