//activate proxy: https://cors-anywhere.herokuapp.com/corsdemo
import { getItem } from "./storage";

const POPETECH_API_URL = process.env.REACT_APP_POPETECH_API_URL;

const headers = {
    post: {
        "Content-Type": "application/json"
    }
};

async function getData(path) {
    let options = {
        "method": "get",
        "contentType": "application/json"
    };
    const response = await fetch(`${POPETECH_API_URL}${path}`, options);

    return response.json();
}

async function postData(path, payload = {}, op = "POST") {
    let options = {
        method: op,
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: headers.post,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload)
    };
    const response = await fetch(`${POPETECH_API_URL}${path}`, options);

    return response.headers.get("content-type") ? response.json() : null;
}

//https://api.pope.tech/organizations/{organizationSlug}/websites
export function addWebsite(projectName, projectURL) {
    let payload = {
        name: projectName + " (AID)",
        full_url: projectURL,
        skip_url_redirect: true
    }

    return postData('/websites', payload);
}

//https://api.pope.tech/organizations/{organizationSlug}/websites/{publicId}
export function deleteWebsite(testId) {
    let websideId = getItem(testId).website_id;

    return postData(`/websites/${websideId}`, {}, 'DELETE');
}

//https://api.pope.tech/organizations/{organizationSlug}/websites/{publicId}/pages
export function addPage(testId, url) {
    let data = getItem(testId);

    let payload = {
        url: url
    }

    return postData(`/websites/${data.website_id}/pages`, payload);
}

export function deletePage(testId, id) {
    let data = getItem(testId);

    let payload = {
        type: "ids",
        pages: [id]
    }

    return postData(`/websites/${data.website_id}/deleted-pages`, payload);
}

//https://api.pope.tech/organizations/{organizationSlug}/scans/{type}/{entityPublicId}
export function startScan(websiteId) {
    return postData(`/scans/websites/${websiteId}`);
}

//https://api.pope.tech/organizations/{organizationSlug}/scans/{publicId}
export function getScanStatus(websiteId) {
    return getData(`/websites/${websiteId}/scans`);
}

// https://{{base_url}}/organizations/{{org_slug}}/reports/result-details?website_filter=aba5e4a0-f828-4aa3-9f0e-55330953a839
export function getFullResults(websiteId) {
    return getData(`/reports/result-details?website_filter=${websiteId}`);
}

// https://api.pope.tech/organizations/{organizationSlug}/reports/aggregates
export function getTotalIssues(websiteId) {
    return getData(`/reports/aggregates?website_filter=${websiteId}`);
}

// https://api.pope.tech/organizations/{organizationSlug}/scans/{publicId}/cancel
export function cancelScan(scanId) {
    return postData(`/scans/${scanId}/cancel`);
}

//organizations/publicis-sapient-2/scans/d0168424-efc5-4d38-97a1-46a7187b5b31/pages?page=1&limit=10&category=alerts&type=noscript_element&sort_by=errors&sort_dir=desc
export function getPagesByError(scanId, slug) {
    return getData(`/scans/${scanId}/pages?type=${slug}`)
}

export function getPages(scanId) {
    return getData(`/scans/${scanId}/pages`);
}