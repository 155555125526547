import { useEffect, useState, createRef, useRef } from "react";
import "./scoringNavigation.css";
import { CheckCircle, ChevronRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { protectedResources } from "../../authConfig";

const NavItem = (props) => {
  const {
    testId,
    criteriaId,
    criteriaText,
    currentCriteriaId,
    setSubmitQuestion,
    headingRef = null,
    dialogRef = null,
    modalOpen = false,
    drawerIsOpen = null,
    completed = false,
    testIncompleteError = null,
    applyIncompleteFocus = false,
    setApplyIncompleteFocus = null,
    incompleteQuestions = null,
    toggleModal = null,
  } = props;

  const navigate = useNavigate();
  const [active, setActive] = useState(criteriaId === currentCriteriaId);
  const liRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    setActive(criteriaId === currentCriteriaId);
  }, [currentCriteriaId]);

  // the list item directly before the active list item is scrolled to the top
  useEffect(() => {
    if (criteriaId === currentCriteriaId && !applyIncompleteFocus) {
      if (drawerIsOpen) {
        liRef.current.parentNode.parentNode.scrollTo({
          top: liRef.current.offsetTop,
          behavior: "smooth",
        });
      } else if (modalOpen) {
        const headerHeight =
          dialogRef.current.querySelector(".dialog-header").offsetHeight;
        liRef.current.parentNode.parentNode.parentNode.parentNode.scrollTo({
          top: liRef.current.offsetTop + headerHeight,
          behavior: "smooth",
        });
      }
    }
  }, [currentCriteriaId, drawerIsOpen, modalOpen]);

  // apply focus to the first incomplete question when the user attemps to submit the questionnaire
  useEffect(() => {
    if (
      incompleteQuestions?.length > 0 &&
      incompleteQuestions[0] === criteriaId &&
      applyIncompleteFocus &&
      (drawerIsOpen || modalOpen)
    ) {
      buttonRef.current.focus();
      setApplyIncompleteFocus(false);

      // scroll the focused li to the top
      if (drawerIsOpen) {
        liRef.current.parentNode.parentNode.scrollTo({
          top: liRef.current.offsetTop,
          behavior: "smooth",
        });
      } else if (modalOpen) {
        const headerHeight =
          dialogRef.current.querySelector(".dialog-header").offsetHeight;
        liRef.current.parentNode.parentNode.parentNode.parentNode.scrollTo({
          top: liRef.current.offsetTop + headerHeight,
          behavior: "smooth",
        });
      }
    }
  }, [applyIncompleteFocus, drawerIsOpen, modalOpen]);

  const changeQuestion = (e) => {
    e.preventDefault();

    // close modal on mobile
    if (toggleModal) {
      toggleModal();
    }

    if (criteriaId === 0) {
      // redirect to basic info screen
      navigate("/BasicInfo", {
        state: { testId },
      });
    } else if (criteriaId !== currentCriteriaId) {
      setSubmitQuestion(criteriaId); // update the user's answer to this question
    } else {
      // if they clicked on the same question, apply focus on the question header
      if (headingRef && headingRef.current) {
        headingRef.current.focus();
      }
    }
  };

  return (
    <li
      className={`${active ? "active" : `${completed ? "completed" : ""}`}`}
      ref={liRef}
    >
      <form onSubmit={(e) => changeQuestion(e)}>
        <button
          type="submit"
          aria-describedby={
            !completed && testIncompleteError ? "test-incomplete-error" : null
          }
          ref={buttonRef}
        >
          <div className="nav-icon">
            {active ? (
              <ChevronRight aria-label="Current" />
            ) : completed ? (
              <CheckCircle aria-label="Completed" />
            ) : (
              <></>
            )}
          </div>
          <span className="nav-number" aria-label={`Test ${criteriaId}`}>
            {criteriaId}
          </span>
          <span className="nav-text">
            {criteriaText}
            {modalOpen && testIncompleteError && (
              <div
                className="test-incomplete-error-cont"
                style={
                  incompleteQuestions[0] === criteriaId
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong className="error_label" id="test-incomplete-error">
                  {testIncompleteError}
                </strong>
              </div>
            )}
          </span>
        </button>
      </form>
    </li>
  );
};

const ScoringNavigation = (props) => {
  const {
    testId,
    currentCriteria,
    incompleteQuestions,
    setIncompleteQuestions,
  } = props;

  const listContRef = useRef(null);
  const [criteria, setCriteria] = useState([]);
  const [responses, setResponses] = useState([]);
  const { execute, error } = useFetchWithMsal({
    scopes: protectedResources.testEndpoint.scopes.read,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    execute("GET", protectedResources.getAllCriteria.endpoint, protectedResources.getAllCriteria.scopes).then(
      (response) => {
        if (response) {
          setCriteria(response);
        }
      }
    );
  }, [execute]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    execute("GET", protectedResources.getAllResponses.endpoint + testId, protectedResources.getAllResponses.scopes).then(
      (response) => {
        if (response) {
          setResponses(response);
        }
      }
    );
  }, [execute, currentCriteria]);

  // handle the changing size of the nav bar on scroll by adjusting the drawer height
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1200px)");

    const handleScroll = () => {
      if (document.documentElement.scrollTop < 1) {
        listContRef.current.style.maxHeight = "calc(100vh - 5rem)";
      } else {
        listContRef.current.style.maxHeight = "calc(100vh - 3rem)";
      }
    };

    if (mediaQuery.matches) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className="scoring-nav" aria-label="Test questions">
      <div className="list-cont" ref={listContRef}>
        {currentCriteria && (
          <ol start="0">
            <NavItem
              key="0"
              criteriaId={0}
              criteriaText="Basic information"
              currentCriteriaId={currentCriteria.criteriaid}
              incompleteQuestions={incompleteQuestions}
              setIncompleteQuestions={setIncompleteQuestions}
              completed={true}
              {...props}
            />
            {criteria?.length > 0 &&
              criteria.map((criterion, index) => (
                <NavItem
                  key={index + 1}
                  criteriaId={criterion.criteriaid}
                  criteriaText={criterion.criteria}
                  currentCriteriaId={currentCriteria.criteriaid}
                  incompleteQuestions={incompleteQuestions}
                  setIncompleteQuestions={setIncompleteQuestions}
                  completed={
                    responses?.filter(
                      (resp) => resp.criteriaid === criterion.criteriaid
                    ).length > 0
                  }
                  {...props}
                />
              ))}
          </ol>
        )}
      </div>
    </nav>
  );
};

export default ScoringNavigation;
