import icon from "../components/icons/open.svg"

function insertOpenIcon(name, listOfClasses, is_id=true) {

    // return a list of elements either by the provided className or Id
    let elems = retrieveElements(name, is_id);

    for (let elem of elems) {
        
        if(elem) {
            const img_tag = `<img src="${icon}" alt="(opens link in new tab)" class="icon" style="height: 1rem; margin-bottom: -3px;  margin-left: 4px;"/>`;
            let linkList = elem.querySelectorAll('a');
    
            if(linkList) {
                for (let i = 0; i < linkList.length; i++) {
                    linkList[i].setAttribute("target", "_blank")
                    for (let j = 0; j < listOfClasses.length; j++) {
                        linkList[i].classList.add(listOfClasses[j]);
                    }
                    linkList[i].insertAdjacentHTML("beforeend", img_tag);
                }
            }
        }
    }
}

function retrieveElements(name, is_id){
    
    let elems = null;

    if(is_id) {
        elems = [document.getElementById(name)];
    }
    else {
        let elementsByClass = document.getElementsByClassName(name);
        elems = Array.from(elementsByClass);
    }
    return elems;
}

function replaceGreaterThan(name, is_id=true){
    
    // return a list of elements either by the provided className or Id
    let elems = retrieveElements(name, is_id);

    for (let elem of elems) {
        if(elem) {
            elem.innerHTML = elem.innerHTML.replaceAll("&amp;gt;",">");  
        }
    }
}

  export { insertOpenIcon, replaceGreaterThan };