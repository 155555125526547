import { createGlobalStyle } from "styled-components";
import Futura from "../../fonts/Futura-Next/FuturaNext_Book.otf";
import FuturaM from "../../fonts/Futura-Next/FuturaNext_Medium.otf";
import Minion from "../../fonts/Minion/MinionPro-Regular.otf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Futura';
        src: url(${Futura}) format('opentype');
    }

    @font-face {
        font-family: 'FuturaM';
        src: url(${FuturaM}) format('opentype');
    }

    @font-face {
        font-family: 'Minion';
        src: url(${Minion}) format('opentype');
    }

    body {
        font-family: 'Futura';
        font-size: 1rem;
        padding: 100px 15% 3%;
    }
    h1 {
        font-family: 'FuturaM';
        font-size: 2.5rem;
        font-weight: 700;
    }

    .centered-text {
        text-align: center;
    }

    .page-header {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    div {
        font-family: 'Futura';
        
    }
    button, .btn {
        border-width: 2px;
        border-style: solid;
        border-color: #2F7ABC;
        background-color: #2F7ABC;
        padding: 1em;
        border-radius: 0;
        border-radius: 10px;
        color: white;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-size: 1rem;
        cursor: pointer;
    }
    button:hover, .btn:hover, button:focus, .btn:focus {
        border-width: 2px;
        border-style: solid;
        border-color: #1D4C72;
        background-color: #1D4C72; 
        color: white;
        text-decoration: underline;
    }

    a, button, select, input, textarea, summary {
        outline-offset: 5px;
    }

    p {
        font-size: 1rem;
        line-height: 160%;
    }

    select {
        border: 1px solid black;
        padding: 1em;
        width: 10em;
        color: white;
        border-radius: 5px;
        margin-top: 0.5em;
        font-family: 'Futura';
        font-size: 1rem;
        background-color: black;

        /* replacing the ugly native downarrow */
        appearance: none;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='white' d='M12 14.975q-.2 0-.388-.075t-.312-.2l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z'/></svg>");
        background-repeat: no-repeat;
        background-position: calc(100% - 0.75rem) center;
    }

    label {
        font-size: 1rem;
    }
    
    .table-container {
        border-spacing: 0;
        border-collapse: separate;
        text-align: left;
        width: 100%;
        border-radius: 25px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
          0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
          0 16px 32px rgba(0, 0, 0, 0.07);
        overflow-x: auto;
    }

    // errors
    .error_label, .required_label {
        color: #cd2026;
    }

    // modal styles
    dialog {
        border-radius: 15px;
        border-width: 0em;
        color: black;
        box-sizing: border-box;
        align-items: center;
        padding: 35px 18px;
    }

    dialog::backdrop {
        background-color: rgba(54, 54, 54, 0.9);
    }

    #favDialog {
        padding: 25px 70px;
    }

    .modal_title {
        vertical-align: middle;
        text-align: center;
        overflow-wrap: normal;
        color: #000000;
        width: 90%;
        margin: auto;
        font-size: 1.2rem;
    }

    .modal_description {
        text-align: center;
        color: #000000;
        box-sizing: border-box;
        display: block;
        margin: 10px auto;
    }

    #admin-modal-password-input {
        height: 2rem;
        border-radius: 8px;
        transition: .4s;
        margin-top: 6px;
        width: 97%;
    }
    
    #admin-modal-password-input:hover {
        border-color: black;
        background-color: #ebebeb;
    }

    .modal_options {
        display: flex;
        flex-direction: row;
        width: 80%;
        column-gap: 1rem;
        margin: auto;
        margin-top: 1rem;
    }

    #favDialog .modal_options {
        width: 100%;
    }

    .modal_btn_container {
        flex-grow: 1;
    }

    .modal_button {
        border-radius: 10px;
        width: 100%;
        margin: 0 !important;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        font-size: 0.8rem;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .modal_button:hover {
        border-color: #1D4C72;
        color: white;
        background-color: #1D4C72;
    }

    .modal_cancel {
        border-color: #2F7ABC;
        color: #2F7ABC;
        background-color: white;
    }

    #delDialog .modal_button {
        border-color: #d21926;
    }

    #delDialog .modal_button:hover {
        border-color: #92111a;
        color: white;
        background-color: #92111a;
    }

    #delDialog .modal_cancel {
        color: #d21926;
        background: #ffffff;
    }

    #delDialog .modal_delete {
        background: #d21926;
        color: white;
    }

    #abandonDialog {
        max-width: 400px;
    }

    dialog .x {
        margin: 3px;
        position: absolute;
        border: hidden !important;
        border-radius: 10px;
        top: 0%;
        right: 0%;
        color: black !important;
        cursor: pointer;
        height: 23%;
        font-size: small;
        background-color: transparent !important;
    }

    dialog .x:hover {
        border: hidden;
        background-color: transparent;
        color: black;
    }

    .delete-btn {
        background-color: #d21926;
        color: white;
        border: none;
        border-radius: 0.2rem;
        padding: 0.2rem 0.35rem 0.2rem 0.35rem;
        cursor: pointer;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    
    .delete-btn:hover, .delete-btn:focus {
        background-color: #92111a;
        border: none;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
    }

    .textarea {
        width: 100%;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 10px;
        box-sizing: border-box;
        font-size: 1em;
    }

    .css-i4bv87-MuiSvgIcon-root {
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        width:1em;
        height:1em;
        display:inline-block;
        fill:currentColor;
        -webkit-flex-shrink:0;
        -ms-flex-negative:0;
        flex-shrink:0;
        -webkit-transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size:1.5rem;
    }

    @media print {
        body {
            margin: 0;
            padding: 0;
        }

        div {
            margin: 0;
            padding: 0;
        }

        .table-container {
            box-shadow: none;
            border: 1px black solid;
            width: fit-content;
        }

        // Prevent repetition of headers on every page
        table thead {
            display: table-row-group;
        }

        // Prevent page breaks in the middle of rows
        table td {
            page-break-inside: avoid;
        }

        .page-header {
            text-align: center;
            font-size: 3rem;
        }

        .print-border {
            border: 1px solid black !important;
            box-sizing: border-box;
        }
    }

    @media only screen and (max-width: 1000px) {
        body {
            padding: 100px 10% 10%;
        }

        .page-header {
            margin-top: 0rem;
        }
    }

    // Not a standard breakpoint but modal starts shrinking at this width
    @media (max-width: 465px) {
        #favDialog {
            padding: 25px 25px;
        }

        #abandonDialog {
            margin: auto 5%;
        }
    }
`;
