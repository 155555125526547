import styled from "styled-components";

export const StyledDiv = styled.div`
  float: right;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 1px black solid;
  margin: 0.5em;
  margin-right: 1.5em;
  margin-top: 1.5em;
  background-color: black;
  color: white;
  border-radius: 5px;
  
  @media print {
    color: white;
  }
`;

export const WrapperDiv = styled.div`
  width: 100%;
  height: 5em;
`;
