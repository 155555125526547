import { ChevronDown } from "react-bootstrap-icons";
import { useComboBox, useFilter, useButton, useListBox, useOption, usePopover, Overlay } from "react-aria";
import { useComboBoxState } from "react-stately";
import { useRef } from "react";
import "./comboBox.css";

const Popover = ({ children, state, ...props }) => {
  let { popoverProps } = usePopover(props, state);

  return (
    <Overlay>
      <div
        {...popoverProps}
        ref={props.popoverRef}
        className="account-overlay"
        style={{ ...popoverProps.style, ...props.style }}
      >
        {children}
      </div>
    </Overlay>
  );
};

const ListBox = (props) => {
  let ref = useRef(null);
  let { listBoxRef = ref, state } = props;
  let { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      className="listbox"
    >
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
};

const Option = ({ item, state }) => {
  let ref = useRef(null);
  let { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref
  );

  return (
    <li
      {...optionProps}
      ref={ref}
      className={`option ${isSelected ? "selected" : (isFocused ? "focused" : (isDisabled ? "disabled" : null))}`}
    >
      {item.rendered}
    </li>
  );
};

const Button = (props) => {
  let ref = props.buttonRef;
  let { buttonProps } = useButton(props, ref);

  return (
    <button {...buttonProps} ref={ref} className="dropdown-button" disabled={props.disabled}>
      {props.children}
    </button>
  );
};

const ComboBox = (props) => {
  const { label } = props;
  const { contains } = useFilter({ sensitivity: "base" });
  const state = useComboBoxState({ ...props, defaultFilter: contains });

  const inputRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  let { buttonProps, inputProps, listBoxProps, labelProps } = useComboBox(
    { ...props, inputRef, buttonRef, listBoxRef, popoverRef },
    state
  );
  inputProps = { ...inputProps, "aria-describedby": "accountNameDesc", "required": true };

  return (
    <div>
      <label {...labelProps}>{label}</label>
      <div>
        <div className="sr-only" id="accountNameDesc">
          As you type, press the enter key or use the up and down arrow keys to
          choose the autocomplete items.
        </div>
        <div className="account-input">
          <input ref={inputRef} {...inputProps} disabled={props.disabled}/>
          <Button {...buttonProps} buttonRef={buttonRef} disabled={props.disabled}>
            <span aria-hidden="true">
              <ChevronDown />
            </span>
          </Button>
        </div>
        {state.isOpen && (
          <Popover
            state={state}
            triggerRef={inputRef}
            popoverRef={popoverRef}
            isNonModal
            placement="bottom start"
            shouldFlip={false}
            style={{ width: `${inputRef?.current.offsetWidth}px` }}
          >
            <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
          </Popover>
        )}
      </div>
    </div>
  );
};

export default ComboBox;