import React from "react";
import {
  StyledDropdown,
  StyledDropdownContainer,
  StyledSelect,
} from "./__styled__/Dropdown";

const DropDown = (props) => {
  let filterAlertEl = null,
    sortAlertEl = null,
    yearAlertEl = null,
    industryAlertEl = null;

  const HandleOnFilterChange = (event) => {
    props.updateFilteredCompanies(event.target.value);

    //Screen Reader information for filter
    /*Note to future developers: changing from == to === removes the innerHTML text*/
    if (event.target.value == 0) {
      filterAlertEl.innerHTML = "Now displaying graph not filtered by quarter";
    }
    if (event.target.value == 1) {
      filterAlertEl.innerHTML =
        "Now displaying graph filtered with tests conducted between January to March inclusive";
    }
    if (event.target.value == 2) {
      filterAlertEl.innerHTML =
        "Now displaying graph filtered with tests conducted between April to June inclusive";
    }
    if (event.target.value == 3) {
      filterAlertEl.innerHTML =
        "Now displaying graph filtered with tests conducted between July to Sepetember inclusive";
    }
    if (event.target.value == 4) {
      filterAlertEl.innerHTML =
        "Now displaying graph filtered with tests conducted between October to December inclusive";
    }
  };

  const HandleOnSortChange = (event) => {
    props.updateFilteredCompanies(event.target.value);

    //Screen reader information for sort
    /*Note to future developers: changing from == to === removes the innerHTML text*/
    if (event.target.value == 5) {
      sortAlertEl.innerHTML =
        "Now displaying graph sorted alphabetically by default";
    } else if (event.target.value == 6) {
      sortAlertEl.innerHTML =
        "Now displaying graph sorted by companies accessibility indicator data scores";
    } else if (event.target.value == 7) {
      sortAlertEl.innerHTML =
        "Now displaying graph sorted by companies in alphabetical order";
    } else if (event.target.value == 8) {
      sortAlertEl.innerHTML =
        "Now displaying graph sorted by oldest to newest test dates";
    } else if (event.target.value == 9) {
      sortAlertEl.innerHTML =
        "Now displaying graph sorted by newest to oldest test dates";
    }
  };

  const HandleOnIndustryChange = (event) => {
    props.updateFilteredCompanies(event.target.value);

    if (event.target.value === 10) {
      industryAlertEl.innerHTML = "Now displaying graph with all industries";
    } else {
      industryAlertEl.innerHTML = `Now displaying graph sorted by ${props.industries[event.target.value - 10].industryname}`;
    }
  };

  const HandleOnYearChange = (event) => {
    if (event.target.value === "No Year") {
      yearAlertEl.innerHTML = "Now displaying graph not sorted by year";
    } else {
      props.updateFilteredYear(event.target.value);
      yearAlertEl.innerHTML = `Now displaying graph sorted by ${event.target.value}`;
    }
  };

  return (
    <StyledDropdownContainer>
      <StyledDropdown>
        <label htmlFor="year-feature">Filter By Year:</label>
        <div
          htmlFor="year-feature"
          className="select-css__label sr-only"
          role="alert"
          aria-live="polite"
          ref={(el) => {
            yearAlertEl = el;
          }}
        >
        </div>
        <StyledSelect
          onChange={HandleOnYearChange}
          id="year-feature"
          className="select-css"
        >
          {props.years.map((year, index) => {
            return (
              <option key={`year${index}`} value={year}>
                {year}
              </option>
            );
          })}
        </StyledSelect>
      </StyledDropdown> 
      <StyledDropdown>
        <label htmlFor="filter-feature">Filter By Quarter:</label>
        <div
          htmlFor="filter-feature"
          className="select-css__label sr-only"
          role="alert"
          aria-live="polite"
          ref={(el) => {
            filterAlertEl = el;
          }}
        >
          {/* Filter By Quarter: */}
        </div>
        <StyledSelect
          onChange={HandleOnFilterChange}
          id="filter-feature"
          className="select-css"
        >
          <option value={0}>No filter</option>
          <option value={1}>Jan. to Mar.</option>
          <option value={2}>Apr. to Jun.</option>
          <option value={3}>Jul. to Sep.</option>
          <option value={4}>Oct. to Dec.</option>
        </StyledSelect>
      </StyledDropdown>
      <StyledDropdown>
        <label htmlFor="sort-feature">Sort By:</label>
        <div
          htmlFor="sort-feature"
          className="select-css__label sr-only"
          role="alert"
          aria-live="polite"
          ref={(el) => {
            sortAlertEl = el;
          }}
        >
          {/* Sort By: */}
        </div>
        <StyledSelect
          onChange={HandleOnSortChange}
          id="sort-feature"
          className="select-css"
        >
          <option value={5}>Default Sort</option>
          <option value={6}>AID Score</option>
          <option value={7}>Alphabetical</option>
          <option value={8}>Oldest to Newest</option>
          <option value={9}>Newest to Oldest</option>
        </StyledSelect>
      </StyledDropdown>
      <StyledDropdown>
        <label htmlFor="industry-filter-feature">Filter By Industry:</label>
        <div
          htmlFor="industry-filter-feature"
          className="select-css__label sr-only"
          role="alert"
          aria-live="polite"
          ref={(el) => {
            industryAlertEl = el;
          }}
        >
          {/* Filter By Industry: */}
        </div>
        <StyledSelect
          onChange={HandleOnIndustryChange}
          id="industry-filter-feature"
          className="select-css"
        >
          {/* options 10-20 */}
          <option value={10}>All industries</option>
          { props.industries && props.industries.length > 0 && props.industries.map( industry => {
            return <option value={industry.industryid + 10}>{industry.industryname}</option>
          })}
        </StyledSelect>
      </StyledDropdown>
    </StyledDropdownContainer>
  );
};

export default DropDown;
